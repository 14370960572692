.title {
  font-size: 65px;
  line-height: 70px; }

.subtitle {
  color: $black;
  font-size: 40px;
  font-family: $mainFont;
  text-align: center;
  .left & {
    text-align: left;
    margin-left: -40px;
    @include sm {
      margin-left: -20px; } }
  .accent & {
    color: $main; }
  @include sm {
    font-size: 27px; } }
