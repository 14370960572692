.event-card {
  font-family: $mainFont;
  position: relative;
  height: 285px;

  .past-section & {
    height: 550px;
    @include xlg {
      height: 285px; } }

  .tickets & {
    height: 480px; }

  &__sell {
    position: absolute;
    width: 60%;
    left: 20%; }

  &__image {
    height: 80%;

    .past-section & {
      @include xlg {
        height: 75%; }
      @include sm {
        height: 80%; } }

    .tickets & {
      padding-top: 37px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__details {
    position: absolute;
    min-height: 40%;
    width: 75%;
    color: $white;
    background: $main;
    left: 12.5%;
    bottom: 0;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include xlg {
      width: 90%;
      left: 5%; }

    .past-section & {
      min-height: 40%;
      @include xlg {
        min-height: 50%; }
      @include sm {
        min-height: 40%; } } }

  &__main-details {
    display: flex;
    @include sm {
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-around; } }

  &__date {
    display: flex;
    flex-direction: column;
    color: $lightGrey;
    padding-right: 25px;
    @include sm {
      flex-direction: row;
      align-items: center; } }

  &__day {
    font-size: 55px;
    line-height: 55px;

    & span {
      display: none; }

    @include sm {
      font-size: 25px;
      line-height: 25px;
      & span {
        display: inline; } } }

  &__rest {
    font-size: 25px;
    line-height: 25px; }

  &__name {
    font-size: 25px;
    line-height: 28px;
    display: flex;
    align-items: center;
    @include sm {
      font-size: 19px; } }

  &__add-details {
    padding-top: 20px; }

  &__price-title {
    font-size: 23px;
    color: $lightGrey;
    padding-right: 25px; }

  &__price {
    font-size: 35px;
    font-family: $contentFont;
    color: $white; } }
