.lang-switcher {
  color: $lightGrey;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  text-transform: uppercase;
  @include md {
    margin-bottom: -20px; }

  &::after {
    content: url("../icons/arrow-light.svg");
    position: absolute;
    top: 20px;
    opacity: 1;
    transition: all $tr ease-in; }

  &.active {

    &::after {
      opacity: 0;
      transform: translateY(50px); } }

  @include sm {
    transform: translateY(-50vh);
    transition: transform $tr;
    margin-top: -15px;
    &.visible {
      transform: translateY(0);
      margin-top: 5px; } }

  &__current {
    position: relative; }

  &__link {
    transition: all $tr;
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;

    .active & {
      pointer-events: all;
      transform: translateY(0);
      opacity: 1; } } }
