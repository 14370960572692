//FONTS
@mixin fontpath($name, $name1, $file) {
  src: local($name), local($name1), url("../fonts/" + $file + ".woff") format("woff"); }

@mixin fontdefault($style, $weight, $name) {
  font-family: $name;
  font-style: $style;
  font-weight: $weight; }

$playfair: 'Playfair';
$arial: "Arial";

@font-face {
  @include fontdefault(normal, 400, $playfair);
  @include fontpath('PlayfairDisplay-Regular', 'PlayfairDisplay-Regular', PlayfairDisplay-Regular); }
