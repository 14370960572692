.hero {
  grid-template-rows: 190px repeat(2, 110px) auto;
  height: 566px;
  @include xl {
    grid-template-rows: 190px 207px !important;
    height: 470px; }
  @include md {
    height: auto; }
  @include sm {
    grid-template-rows: 120px 137px !important; }

  .hero-error & {
    height: auto;
    @include md {
      grid-template-rows: 190px 80px !important; }
    @include sm {
      grid-template-rows: 120px 50px !important; } }

  .hero-cabinet & {
    height: auto;
    @include sm {
      grid-template-rows: 120px !important; } }

  .hero-front & {
    height: 100vh; }


  &__top {
    position: relative;
    grid-area: 1/1/span 2/-1;
    background: $main;
    @include xl {
      grid-area: 1/1/span 1/-1; }

    &::before {
      content: '';
      position: absolute;
      width: 140vw;
      height: 100%;
      left: -40vw;
      background: $main;
      z-index: -1; } }

  &__image {
    grid-area: 3/1/span 2/-1;
    position: relative;
    @include xl {
      grid-area: 2/1/span 3/-1; }
    @include md {
      grid-area: 2/1/span 1/-1;

      .hero-front & {
        grid-area: 2/1/span 3/-1; } }

    & img {
      position: absolute;
      width: 100vw;
      height: 100%;
      object-fit: cover;
      left: calc((-100vw + 1340px) / 2);
      @include xl {
        left: -40px;
        right: -40px; }
      @include sm {
        right: -20px;
        left: -20px; } } } }


