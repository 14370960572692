.club-open {
  font-family: $mainFont;

  &__content {
    color: $main;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    @include xs {
      padding: 40px 0; }

    & span {
      text-align: center; } }

  &__text {
    font-size: 40px;
    @include xs {
      font-size: 25px; } }

  &__number {
    font-size: 240px;
    line-height: 175px;
    @include xs {
      font-size: 170px;
      line-height: 154px; } } }
