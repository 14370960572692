.generic-content {
  font-family: $arial;
  text-align: justify;
  align-items: center;
  line-height: 1.7;
  color: $darkGrey;
  p + p {
    margin-top: 22px; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $playfair;
    font-weight: 800;
    line-height: 1.7;
    margin: 22px 0; }
  h1 {
    font-size: 4.2rem;
    line-height: 1;
    @include xlg {
      font-size: 3.5rem; }
    @include sm {
      font-size: 3rem; } }
  h2 {
    font-size: 3.5rem;
    line-height: 1;
    @include xlg {
      font-size: 3rem; }
    @include sm {
      font-size: 2.5rem; } }
  h3 {
    font-size: 2.5rem;
    line-height: 1;
    @include sm {
      font-size: 2rem;
      line-height: 1; } }
  h4 {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 16px;
    @include sm {
      font-size: 1.7rem;
      line-height: 21px; } }
  h5,
  h6 {
    font-size: 1.7rem;
    line-height: 21px;
    margin-bottom: 14px;
    @include sm {
      font-size: 1.2rem; } }
  img,
  iframe {
    max-width: 100%;
    margin: 60px 0;
    @include sm {
      margin: 30px 0; } }
  p {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  ol,
  ul {
    margin: 22px 0; }
  ul,
  ol {
    li {
      position: relative;
      padding-left: 40px;
      &:not(:last-child) {
        margin-bottom: 8px; }
      &:before {
        position: absolute;
        left: 20px;
        //top: 0.85em
        @include sm {
          top: 0.75em; } } } }
  ul {
    li {
      &:before {
        content: "";
        display: inline-block;
        height: 8px;
        width: 8px;
        top: 8px;
        border-radius: 50%;
        background: $main; } } }
  ol {
    counter-reset: list;
    li {
      &:before {
        content: counter(list) " ";
        counter-increment: list;
        font-family: inherit;
        font-weight: 700;
        background: $main;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; } } }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    text-align: left;
    tr {
      padding: 25px;
      &:first-child {
        font-family: $playfair;
        background: $lightGrey;
        font-weight: 800;
        color: $main;
        td:first-child {
          background: $white; } } }
    th {
      background: rgba(218, 227, 230, 0.4);
      font-weight: 800;
      padding: 25px; }
    td {
      font-family: $arial;
      padding: 25px;
      &:first-child {
        font-family: $playfair;
        background: $white;
        font-weight: 800;
        color: $main; } } }
  blockquote,
  q {
    padding: 16px 24px;
    font-family: $arial;
    font-size: 1.2rem;
    line-height: 2;
    color: $darkGrey;
    font-style: italic;
    margin: 16px 0 16px 0;
    @include sm {
      font-size: 1.1rem;
      display: block;
      width: 100%;
      float: none;
      margin: 0;
      max-width: none; } }
  a {
    font-family: $arial;
    font-weight: 800;
    color: $main;
    &:hover {
      border-bottom: 1px solid $main; } }

  iframe {
    width: 100%;
    height: 400px !important;
    margin-top: 0;
    @include sm {
      height: 200px !important; } }
  i,
  em {
    font-style: italic; }
  pre {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-y: hidden; }
  pre code {
    padding: 10px; } }
