.full-gallery {
    position: relative;

    &__images-wrapper {
      width: 100vw;
      margin-left: calc((-100vw + 1340px) / 2);
      display: flex;
      justify-content: space-around;
      @include xl {
        padding-top: 0;
        margin: 0 -40px; }
      @include sm {
        margin: 0 -20px; } }

    &__card {
      width: 100%; }

    &__image {
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover; } } }
