.events {
  font-family: $mainFont;
  @include md {
    padding-bottom: 70px; }
  @include sm {
    padding-bottom: 30px; }

  &__content {
    grid-template-rows: minmax(80px, 1fr); }

  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-top: -50px; } }

  &__future {
    grid-area: 2/1/span 1/-1; }

  &__past {
    grid-area: 3/1/span 1/-1; }

  &__calendar {
    grid-area: 4/1/span 1/-1;
    margin: 0 auto;
    padding: 60px;
    @include md {
      padding: 40px; }
    @include sm {
      padding: 0 0 30px; }

    &:hover {
      & svg {
        & path {
          transition: all $tr ease-in-out;
          opacity: .9; } } } }

  &__button {
    background: transparent; }

  &__link {
    grid-area: 5/1/span 1/-1;
    width: 400px;
    margin: 0 auto;
    @include xs {
      width: 100%; } }

  &__arrow {
    grid-area: 6/1/span 1/-1;
    padding: 30px;
    @include md {
      display: none; } } }


