.cabinet {
  flex-grow: 1;
  @include sm {
    padding: 5vh 0; }
  &__grid {
    grid-template-rows: auto; }

  &__title {
    grid-area: 1/3/span 1/span 8;
    position: relative;
    margin-top: -190px;
    @include md {
      grid-area: 1/1/span 1/-1;
      margin-top: -150px; }
    @include sm {
      margin-top: 0; } }

  &__content {
    grid-area: 2/4/span 1/span 6;
    align-self: center;
    @include xl {
      grid-area: 2/3/span 1/span 8; }
    @include md {
      grid-area: 2/2/span 1/6; }
    @include sm {
      grid-area: 2/1/span 1/-1; } }

  &__form {
    color: $black;
    display: flex;
    flex-direction: column;
    padding: 5vh 0;
    @include md {
      padding: 10vh 0; }
    @include sm {
      padding: 5vh 0 0; }
    @include xs {
      height: 100%;
      justify-content: center; } }

  &__fields {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 3vh;
    position: relative;

    @include sm {
      flex-direction: column;
      justify-content: space-between; }
    @include xs {
      padding-bottom: 3vh; } }

  &__label {
    font-size: 25px;
    text-transform: uppercase;
    text-align: right;
    position: relative;

    @include sm {
      text-align: left; }
    @include xs {
      font-size: 5vw; } }

  &__input {
    font-size: 25px;
    background: transparent;
    border-bottom: 1px solid rgba(61, 61, 61, .4);
    width: 50%;
    margin-left: 10px;
    padding-left: 10px;
    color: $black;

    @include sm {
      width: calc(100% - 30px);
      min-height: 60px;
      margin-left: 0;
      padding-left: 0; }
    @include xs {
      min-height: 5vh;
      font-size: 3vh; } }

  &__edit {
    width: 23px;
    height: 23px;
    align-self: flex-end;
    margin-left: 15px;

    @include sm {
      position: absolute;
      bottom: 3vh; } }

  &__select {
    border-top: none;
    border-right: none;
    border-left: none;
    flex-shrink: 0;
    margin-right: 38px; }


  &__control {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    @include md {
      margin-top: 5vh; }
    @include xs {
      flex-direction: column; } }

  &__button {
    width: 45%;
    min-height: 60px;
    border-radius: 40px;
    background: $lightGrey;
    color: $main;
    font-family: $mainFont;
    font-size: 25px;
    line-height: 30px;
    padding: 10px;
    transition: all $tr ease-in-out;
    &:hover {
      background: $main;
      color: $lightGrey; }
    @include xl {
      width: 30%; }
    @include md {
      width: 40%; }
    @include sm {
      width: 50%;
      min-height: 80px; }
    @include xs {
      width: 100%; } } }

.cabinet__button + .cabinet__button {
  margin-left: 10%;
  @include xs {
    margin-left: 0;
    margin-top: 10%; } }
