.arrow-top {
  padding: 90px 0;
  display: flex;
  justify-content: center;
  position: relative;
  @include xl {
    width: calc(100% + 80px);
    margin: 0 -40px; }
  @include md {
    padding: 50px 0; }
  &__image {
    width: 63px;
    height: 63px;
    border: 1px solid #A4A4A4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    transition: all $tr ease-in-out;
    @include sm {
      width: 45px;
      height: 45px;
      & svg {
        height: 18px; } }

    &:hover {
      background: $main;
      & svg {
        & path {
          fill: $lightGrey; } } }
    .dark & {
      background: $main;
      & svg {
          & path {
              fill: $lightGrey; } }
      &:hover {
        background: $white;
        & svg {
          & path {
            fill: $main; } } } } }
  &::before, &::after {
    content: '';
    position: absolute;
    width: calc(50% - 31px);
    top: -50%;
    height: 100%;
    border-bottom: 1px solid #A4A4A4;
    @include sm {
      width: calc(50% - 22px); } }
  &::before {
    left: 0; }
  &::after {
    right: 0; } }

