.error {
  flex-grow: 1;
  &__grid {
    grid-template-rows: auto;
    @include md {
      grid-template-rows: 1fr 3fr; }
    @include xs {
      grid-template-rows: 1fr 2fr; } }

  &__title {
    grid-area: 1/3/span 1/span 8;
    position: relative;
    @include md {
      grid-area: 1/1/span 1/-1; } }

  &__title-link {
    position: absolute;
    top: 64px;
    left: 60px;
    font-size: 25px;
    color: $main;
    display: flex;
    @include xl {
      font-size: 2vw; }
    @include md {
      top: 65px;
      font-size: 25px; }
    @include sm {
      top: 35px;
      font-size: 20px; }
    @include xs {
      left: 25px;
      top: 25px;
      font-size: 4vw;
      flex-direction: column; } }

  &__link-decor {
    width: 80px;
    @include xl {
      width: 70px; }
    @include xs {
      width: 40px; } }

  &__content {
    grid-area: 2/3/span 1/span 4;
    align-self: center;
    @include xl {
      grid-area: 2/3/span 1/span 6; }
    @include md {
      grid-area: 2/2/span 1/6; }
    @include sm {
      grid-area: 2/1/span 1/-1; } }

  &__subtitle {
    margin-left: -$gap;
    .subtitle-small {
      padding: 15px 0; }
    @include sm {
      margin-left: 0; } }

  &__text {
    font-family: $contentFont;
    color: $black;
    line-height: 25px; }

  &__link {
    color: $main;
    text-decoration: underline; } }
