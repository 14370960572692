.arrows {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include sm {
    width: 90%;
    margin: 0 auto; }
  button {
    background: transparent;
    svg {
      fill: $main;
      stroke: $main; } } }
