.slogan {
  font-family: $mainFont;
  &__grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 20px;
    @include md {
      grid-template-columns: repeat(6,1fr); } }

  &__text {
    position: absolute;
    top: 33%;
    width: 100%;
    padding: 0 20%;
    text-align: center;
    & h3 {
      font-size: 40px!important;
      @include lg {
        font-size: 3vw!important; }
      @include md {
        font-size: 6vw!important; } }
    @include lg {
      top: 28%; }
    @include md {
      padding: 0 25%; } }


  &__decor {
    grid-column: 4/span 6;
    position: relative;
    & img {
      width: 100%; }
    @include md {
      grid-column: 1/-1; } } }

