.section-title {
  background: $lightGrey;
  min-height: 156px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .error & {
    justify-content: flex-end;
    padding: 0 38px;
    @include xs {
      padding: 0 25px; } }

  .cabinet & {
    @include lg {
      padding: 15px; } }

  @include lg {
    padding: 38px; }
  @include md {
    padding: 30px;
    justify-content: flex-start; }
  @include sm {
    justify-content: center;
    min-height: 91px;
    padding: 18px;
    .events & {
      justify-content: flex-start; } }


  .dark & {
    background: $main; }

  &__image {
    position: absolute;
    left: 38px;
    @include md {
      position: relative;
      left: unset; }
    @include sm {
      display: none; }

    .error & {
      @include md {
        position: absolute;
        left: 38px; }
      @include sm {
        display: block; }

      @include xs {
        display: none; } }

    .dark & {
      svg {
        path {
          fill: $lightGrey; } } }

    &--add {
      position: absolute;
      right: 38px;
      @include sm {
        height: 75%;
        right: 17px;
        & img {
          height: 100%; } } } }

  &__text {
    display: block;
    font-family: $mainFont;
    font-size: 60px;
    line-height: 60px;
    color: $main;
    max-width: 500px;

    .dark & {
      color: $lightGrey; }

    .error & {
      font-size: 90px;
      max-width: unset;
      @include xl {
        font-size: 6vw; }
      @include xs {
        font-size: 30px; } }

    .cabinet & {
      font-size: 50px;
      padding: 15px;
      @include xl {
        font-size: 4vw;
        line-height: 1; }
      @include xs {
        font-size: 8vw; } }

    @include md {
      padding-left: 20px;
      max-width: unset; }
    @include sm {
      font-size: 40px;
      line-height: 40px;
      padding-left: 0; } } }

