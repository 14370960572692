.partners {
  padding-top: 90px;
  @include md {
    padding-top: 50px; }

  &__content {
    grid-template-rows: 80px minmax(80px, 1fr);
    background: $lightGrey;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 140vw;
      height: 100%;
      left: -40vw;
      background: $lightGrey;
      z-index: -1; } }

  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-top: -50px; } }

  &__slider {
    grid-area: 2/1/span 1/-1;
    position: relative;
    margin-top: 120px;
    @include sm {
      margin-top: 60px; } }

  &__slider-list {
    //display: flex
    justify-content: space-between; }

  &__logo {
    display: flex !important;
    justify-content: center; }

  &__slider-arrows {
    position: absolute;
    top: 50%;
    width: 106%;
    left: -3%;
    @include xl {
      width: 100%;
      left: 0; }
    @include sm {
      display: none; } }

  &__arrow-top {
    grid-area: 3/1/span 1/-1;
    padding: 90px 0 0;
    width: 100%;
    @include sm {
      padding: 40px 0 0; } } }
