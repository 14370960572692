.content {
  padding-bottom: 70px;
  @include md {
    padding-bottom: 30px; }

  &__grid {
    grid-template-rows: auto; }

  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-top: -50px; } }

  &__event-title {
    grid-area: 2/3/span 1/span 8;
    font-family: $mainFont;
    font-size: 40px;
    line-height: 50px;
    color: $black;
    text-align: center;
    padding: 60px 0;
    @include md {
      grid-area: 2/1/span 1/-1; }
    @include sm {
      padding: 30px 0; } }

  &__entering {
    grid-area: 3/3/span 1/span 8;
    @include md {
      grid-area: 3/1/span 1/-1; } }

  &__image {
    grid-area: 4/1/span 1/-1;
    margin: 60px 0;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

    @include xl {
      margin: 60px -40px; }
    @include sm {
      margin: 30px -40px; } }

  &__content {
    grid-area: 5/3/span 1/span 8;
    @include md {
      grid-area: 5/1/span 1/-1; } }

  &__arrow {
    padding: 110px 0 90px;
    width: 100%;
    grid-area: 6/1/span 1/-1;
    @include md {
      padding: 40px 0; }
    @include xs {
      display: none; } }

  &__footer {
    grid-area: 7/1/span 1/-1;
    @include md {
      margin: 0 -40px; }
    @include sm {
      margin: 0 -20px; } }

  &__events {
    display: flex;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end; } }

  &__event-card {
    width: 50%; }

  &__link {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include md {
      padding: 30px 40px 20px; }
    @include sm {
      padding: 30px 15px 20px; }

    & span {
      font-family: $mainFont; }

    & .arrow {
      margin-inline-end: 40px;
      @include sm {
        margin-inline-end: 10px; } } }

  &__link-main {
    display: flex;
    align-items: center;
    & span {
      color: $black;
      font-size: 25px;
      @include sm {
        font-size: 19px; } }
    & .arrow-next {
      transform: rotate(180deg); }
    .content__next-link & {
      flex-direction: row-reverse; } }

  &__next-link {
    & span {
      text-align: right; } }

  &__link-date {
    display: none;
    padding-top: 25px;
    color: $main;
    font-size: 25px;
    @include sm {
      display: block; } }

  &__link-title {
    display: none;
    color: $main;
    font-size: 19px;
    @include sm {
      display: block; } }

  &__event {
    @include sm {
      display: none; } }

  &__events-button {
    display: flex;
    justify-content: center;
    margin-top: -70px;
    @include md {
      margin-top: 0; }
    @include xs {
      display: none; } } }

