.image-text {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: minmax(80px,1fr);
    grid-column-gap: $gap;
    @include md {
      grid-template-rows: 80px 1fr; }
    @include sm {
      display: flex;
      flex-direction: column;
      &.reverse {
        flex-direction: column-reverse; } } }

  &__image {
    grid-area: 1/1/span 2/span 5;
    @include xl {
      margin-left: -2*($gap); }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

    @include md {
      grid-area: 2/1/span 1/span 2;
      margin-right: -$gap; }
    @include xs {
      height: 40vh; } }

  &__text {
    grid-area: 2/6/span 1/span 7;
    padding: 50px;
    margin-left: -($gap);
    background: $lightGrey;

    .background & {
      background: $main;
      color: $lightGrey!important; }
    @include xl {
      margin-right: -2*($gap); }
    @include md {
      grid-area: 1/3/span 2/span 5; }
    @include sm {
      margin-left: -$gap;
      padding: 30px; } } }
