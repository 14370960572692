.label {
  font-family: $contentFont;
  &__button {
    background: transparent;
    @include xs {
      & svg {
        height: 10px; } } }
  &__text {
    font-size: 22px;
    font-weight: 700;
    color: $black;
    @include xs {
      font-size: 16px; } } }
