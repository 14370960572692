.clubhouse {
  color: $lightGrey;
  font-family: $mainFont;
  @include xs {
    padding-top: 53px; }

  &__content {
    grid-template-rows: auto minmax(80px, 1fr) !important;
    @include sm {
      display: flex;
      flex-direction: column; } }

  &__text {
    grid-area: 3/7/span 1/-1;
    padding-left: 40px;
    @include md {
      grid-area: 3/4/span 1/-1; }
    @include sm {
      padding-left: 0; } }


  &__text-title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 92px;
      height: 10px;
      background: $main; } }

  &__image {
    grid-area: 3/1/-1/span 6;
    @include xl {
      margin-left: -40px; }
    @include md {
      grid-area: 3/1/-1/span 3; }
    @include sm {
      margin: 0 -40px; }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
