.guests-info {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 80px) 1fr;
    grid-gap: $gap;
    @include md {
      grid-template-columns: repeat(6,1fr);
      grid-template-rows: auto; } }
  &__title {
    grid-area: 1/3/span 2/span 8;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-bottom: -50px; } }

  &__bg {
    background: $main;
    grid-area: 2/1/span 3/-1;
    @include xl {
      margin: 0 -40px; }
    @include sm {
      grid-area: 2/1/span 4/-1; } }

  &__text {
    padding: 60px 45px 60px 0;
    @include md {
      padding: 0; } }

  &__text-top {
    grid-area: 3/3/span 1/-1;
    position: relative;
    @include md {
      grid-area: 3/1/span 1/-1;
      padding: 50px 0 20px; }
    @include sm {
      padding: 70px 0 30px; }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80%;
      border-bottom: 1px solid $lightGrey;
      @include md {
        display: none; } } }

  &__text-bottom {
    grid-area: 4/5/span 1/-1;
    display: flex;
    align-items: flex-start;
    @include md {
      grid-area: 4/3/span 1/-1;
      padding: 0 0 60px 60px; }
    @include sm {
      grid-area: 5/1/span 1/-1;
      padding: 20px 0; } }

  &__amount {
    grid-area: 4/1/span 1/span 4;
    display: flex;
    align-items: flex-end;
    padding-bottom: 60px;
    color: $lightGrey;
    @include md {
      grid-area: 4/1/span 1/span 2;
      flex-direction: column;
      align-items: flex-start; }
    @include sm {
      grid-area: 4/1/span 1/-1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0; } }

  &__amount-description {
    font-size: 40px;
    line-height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    @include sm {
      font-size: 32px;
      line-height: 30px; }

    &::before {
      content: '';
      position: absolute;
      top: -45px;
      left: 0;
      width: 92px;
      height: 10px;
      background: $lightGrey;
      @include sm {
        top: -25px; } } }

  &__amount-value {
    font-size: 160px;
    line-height: 120px;
    text-align: center;
    @include md {
      font-size: 240px;
      line-height: unset;
      margin: -60px 0 60px; }
    @include sm {
      font-size: 165px;
      margin: -60px 40px 0 0; } }


  &__amount-item {
    width: 50%;
    @include md {
      width: auto; }
    @include sm {
      width: 50%; } } }
