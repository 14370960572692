.icon-arrow-left {
  width: 3.19em;
  height: 1em;
  fill: currentColor;
}
.icon-arrow-light {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-arrow-right {
  width: 3.19em;
  height: 1em;
  fill: currentColor;
}
.icon-close-button {
  width: 1.04em;
  height: 1em;
  fill: currentColor;
}
.icon-edit {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-small-left {
  width: 0.94em;
  height: 1em;
  fill: currentColor;
}
.icon-small-right {
  width: 0.94em;
  height: 1em;
  fill: currentColor;
}
