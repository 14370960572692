.button {
  background: $main;
  min-height: 74px;
  padding: 0 20px;
  border-radius: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all $tr ease-in-out;
  color: $white;
  font-family: $mainFont;
  border: 1px solid $main;
  @include md {
    min-height: 65px; }
  &:hover {
    background: $lightGrey;
    color: $main; }
  &__link {
    font-size: 40px;
    line-height: 32px;
    @include md {
      font-size: 25px; } } }

.section-button {
  margin: 0 auto;
  max-width: 400px; }
