.tickets {
  font-family: $mainFont;
  &__content {
    grid-template-rows: minmax(80px, 1fr); }

  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-top: -50px; } }

  &__list {
    grid-area: 2/1/span 1/-1;
    padding: 74px 0;
    @include sm {
      padding: 36px 0 0 0; } }

  &__slider {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include md {
      margin: 0 -40px; } }

  &__card {
    width: 50%;
    max-width: 600px;
    margin-bottom: 60px;
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin: 0 20px;
      width: 100vw !important;
      max-width: unset; } }

  &__slider-arrows {
    display: none;
    @include sm {
      display: block;
      padding: 30px 0; } } }


