.auth {
  color: $lightGrey;
  width: 100%;
  pointer-events: none;
  .is-open & {
    pointer-events: all;
    @include sm {
      width: 80vw; }
    @include xs {
      width: 100%; } }

  &__inner {
    max-width: 850px;
    margin: 0 auto; }

  &__wrapper {
    position: relative; }

  &__close {
    background: transparent;
    position: absolute;
    top: 34px;
    right: 34px;
    @include xs {
      top: 20px;
      right: 20px;
      & svg {
        height: 16px;
        width: 16px; } } }

  &__body {
    background: $main;
    padding: 84px;
    box-shadow: 0 0 150px #2E000A;
    @include sm {
      padding: 80px 40px; }
    @include xs {
      height: 100vh;
      width: 100%;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }

  &__title {
    min-height: 100px;
    font-family: $mainFont;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 50px;
    color: $white;
    text-align: center;
    @include sm {
      font-size: 5vw; }
    @include xs {
      font-size: 9vw;
      line-height: 1; } }

  &__form {
    display: flex;
    flex-direction: column;
    @include xs {
      height: 100%;
      justify-content: center; } }

  &__fields {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 45px;
    @include sm {
      flex-direction: column;
      justify-content: space-between; }
    @include xs {
      padding-bottom: 4vh; } }

  &__label {
    font-size: 25px;
    text-transform: uppercase;
    text-align: right;
    @include sm {
      text-align: left; }
    @include xs {
      font-size: 6vw; } }

  &__input {
    font-size: 25px;
    background: transparent;
    border-bottom: 1px solid rgba(239, 244, 242, .4);
    width: 50%;
    margin-left: 10px;
    padding-left: 10px;
    color: $lightGrey;
    &--phone {
      &::-webkit-input-placeholder {
        color: $lightGrey;
        opacity: .3; } }
    @include sm {
      width: 100%;
      min-height: 60px;
      margin-left: 0;
      padding-left: 0; }
    @include xs {
      min-height: 6vh; } }

  &__select {
    border-top: none;
    border-right: none;
    border-left: none;
    flex-shrink: 0; }


  &__submit {
    width: 240px;
    margin: 5vh auto 0;
    min-height: 60px;
    border-radius: 40px;
    background: $lightGrey;
    color: $main;
    font-family: $mainFont;
    font-size: 25px; } }
