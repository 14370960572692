@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, a:focus {
  outline: none;
  font-weight: normal;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Playfair";
  font-style: normal;
  font-weight: 400;
  src: local("PlayfairDisplay-Regular"), local("PlayfairDisplay-Regular"), url("../fonts/PlayfairDisplay-Regular.woff") format("woff");
}

.generic-content {
  font-family: "Arial";
  text-align: justify;
  align-items: center;
  line-height: 1.7;
  color: #747474;
}

.generic-content p + p {
  margin-top: 22px;
}

.generic-content h1,
.generic-content h2,
.generic-content h3,
.generic-content h4,
.generic-content h5,
.generic-content h6 {
  font-family: "Playfair";
  font-weight: 800;
  line-height: 1.7;
  margin: 22px 0;
}

.generic-content h1 {
  font-size: 4.2rem;
  line-height: 1;
}

@media only screen and (max-width: 1920px) {
  .generic-content h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .generic-content h1 {
    font-size: 3rem;
  }
}

.generic-content h2 {
  font-size: 3.5rem;
  line-height: 1;
}

@media only screen and (max-width: 1920px) {
  .generic-content h2 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .generic-content h2 {
    font-size: 2.5rem;
  }
}

.generic-content h3 {
  font-size: 2.5rem;
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  .generic-content h3 {
    font-size: 2rem;
    line-height: 1;
  }
}

.generic-content h4 {
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
  .generic-content h4 {
    font-size: 1.7rem;
    line-height: 21px;
  }
}

.generic-content h5,
.generic-content h6 {
  font-size: 1.7rem;
  line-height: 21px;
  margin-bottom: 14px;
}

@media only screen and (max-width: 768px) {
  .generic-content h5,
  .generic-content h6 {
    font-size: 1.2rem;
  }
}

.generic-content img,
.generic-content iframe {
  max-width: 100%;
  margin: 60px 0;
}

@media only screen and (max-width: 768px) {
  .generic-content img,
  .generic-content iframe {
    margin: 30px 0;
  }
}

.generic-content p {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.generic-content ol,
.generic-content ul {
  margin: 22px 0;
}

.generic-content ul li,
.generic-content ol li {
  position: relative;
  padding-left: 40px;
}

.generic-content ul li:not(:last-child),
.generic-content ol li:not(:last-child) {
  margin-bottom: 8px;
}

.generic-content ul li:before,
.generic-content ol li:before {
  position: absolute;
  left: 20px;
}

@media only screen and (max-width: 768px) {
  .generic-content ul li:before,
  .generic-content ol li:before {
    top: 0.75em;
  }
}

.generic-content ul li:before {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  top: 8px;
  border-radius: 50%;
  background: #47101C;
}

.generic-content ol {
  counter-reset: list;
}

.generic-content ol li:before {
  content: counter(list) " ";
  counter-increment: list;
  font-family: inherit;
  font-weight: 700;
  background: #47101C;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.generic-content table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: left;
}

.generic-content table tr {
  padding: 25px;
}

.generic-content table tr:first-child {
  font-family: "Playfair";
  background: #DCD6CA;
  font-weight: 800;
  color: #47101C;
}

.generic-content table tr:first-child td:first-child {
  background: #EFF4F2;
}

.generic-content table th {
  background: rgba(218, 227, 230, 0.4);
  font-weight: 800;
  padding: 25px;
}

.generic-content table td {
  font-family: "Arial";
  padding: 25px;
}

.generic-content table td:first-child {
  font-family: "Playfair";
  background: #EFF4F2;
  font-weight: 800;
  color: #47101C;
}

.generic-content blockquote,
.generic-content q {
  padding: 16px 24px;
  font-family: "Arial";
  font-size: 1.2rem;
  line-height: 2;
  color: #747474;
  font-style: italic;
  margin: 16px 0 16px 0;
}

@media only screen and (max-width: 768px) {
  .generic-content blockquote,
  .generic-content q {
    font-size: 1.1rem;
    display: block;
    width: 100%;
    float: none;
    margin: 0;
    max-width: none;
  }
}

.generic-content a {
  font-family: "Arial";
  font-weight: 800;
  color: #47101C;
}

.generic-content a:hover {
  border-bottom: 1px solid #47101C;
}

.generic-content iframe {
  width: 100%;
  height: 400px !important;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .generic-content iframe {
    height: 200px !important;
  }
}

.generic-content i,
.generic-content em {
  font-style: italic;
}

.generic-content pre {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-y: hidden;
}

.generic-content pre code {
  padding: 10px;
}

.icon-arrow-left {
  width: 3.19em;
  height: 1em;
  fill: currentColor;
}

.icon-arrow-light {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-arrow-right {
  width: 3.19em;
  height: 1em;
  fill: currentColor;
}

.icon-close-button {
  width: 1.04em;
  height: 1em;
  fill: currentColor;
}

.icon-edit {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-small-left {
  width: 0.94em;
  height: 1em;
  fill: currentColor;
}

.icon-small-right {
  width: 0.94em;
  height: 1em;
  fill: currentColor;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 22px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.slick-dots li button {
  display: block;
  padding: 3px;
  background: none;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: red;
  cursor: pointer;
}

.slick-dots li button:before {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #979795;
  transition: all 0.1s ease;
}

.slick-dots li button:hover:before {
  background: #4E4E4E;
}

.slick-dots li.slick-active button:before {
  background: #4E4E4E;
}

.out {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}

.inner {
  position: relative;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 768px) {
  .inner {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr minmax(80px, 1fr);
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.title {
  font-size: 65px;
  line-height: 70px;
}

.subtitle {
  color: #3D3D3D;
  font-size: 40px;
  font-family: "Playfair", sans-serif;
  text-align: center;
}

.left .subtitle {
  text-align: left;
  margin-left: -40px;
}

@media only screen and (max-width: 768px) {
  .left .subtitle {
    margin-left: -20px;
  }
}

.accent .subtitle {
  color: #47101C;
}

@media only screen and (max-width: 768px) {
  .subtitle {
    font-size: 27px;
  }
}

.header {
  font-family: "Playfair", sans-serif;
  position: absolute;
  width: 100%;
}

.header__logo-text {
  grid-area: 1/1/span 1/span 2;
  display: block;
  font-size: 32px;
  color: #DCD6CA;
  text-align: center;
  padding: 30px 0;
  z-index: 3;
}

@media only screen and (max-width: 1024px) {
  .header__logo-text {
    grid-area: 1/3/span 1/span 2;
    font-size: 42px;
    line-height: 46px;
    width: auto;
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .header__logo-text {
    font-size: 28px;
    line-height: 25px;
    padding: 20px 20px 20px 0;
  }
}

.header__tel {
  grid-area: 1/3/span 1/span 3;
  display: block;
  font-size: 25px;
  color: #DCD6CA;
  align-self: center;
  justify-self: center;
}

@media only screen and (max-width: 1440px) {
  .header__tel {
    grid-area: 1/3/span 1/span 3;
  }
}

@media only screen and (max-width: 1240px) {
  .header__tel {
    display: none;
  }
}

.header__navbar {
  grid-template-rows: auto;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  .header__navbar {
    grid-template-rows: 190px auto;
    width: 100%;
    left: unset;
  }
}

@media only screen and (max-width: 768px) {
  .header__navbar {
    grid-template-rows: auto;
  }
}

.header__menu {
  grid-area: 1 / 6 / span 1 / -1;
  align-self: center;
}

@media only screen and (max-width: 1240px) {
  .header__menu {
    grid-area: 1 / 5 / span 1 / -1;
  }
}

.header__modal {
  width: 1px;
  height: 1px;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.header__modal.is-open {
  opacity: 1;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .header__modal.is-open {
    z-index: 8;
  }
}

.header__burger-menu {
  cursor: pointer;
  display: none;
  position: relative;
  align-self: center;
  justify-self: flex-end;
  z-index: 5;
  transition: 0.3s;
  grid-area: 1 / 6 / span 1 / -1;
}

@media only screen and (max-width: 1024px) {
  .header__burger-menu {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

.header__burger-menu.active .header__burger-lines {
  background-color: transparent;
}

.header__burger-menu.active .header__burger-lines::before {
  top: 0;
  transform: rotate(45deg);
}

.header__burger-menu.active .header__burger-lines::after {
  top: 0;
  transform: rotate(-45deg);
}

.header__burger-decor {
  opacity: .2;
  width: 73px;
  height: 73px;
  transition: opacity 0.3s;
}

.active .header__burger-decor {
  opacity: 0;
}

.header__burger-button {
  position: absolute;
  height: 22px;
  width: 33px;
  display: flex;
  align-items: center;
}

.header__burger-lines {
  width: 33px;
}

.header__burger-lines, .header__burger-lines::before, .header__burger-lines::after {
  position: absolute;
  height: 3px;
  background-color: #DCD6CA;
  transition: 0.3s;
}

.header__burger-lines::before {
  content: '';
  top: -8px;
  width: 27px;
  left: 3px;
}

.header__burger-lines::after {
  content: '';
  top: 8px;
  width: 27px;
  right: 3px;
}

.header__lang-switcher {
  grid-area: 2/12/span 1/span 1;
  justify-self: end;
  padding-right: 23px;
}

@media only screen and (max-width: 1440px) {
  .header__lang-switcher {
    grid-area: 1/12/span 1/span 1;
    align-self: end;
  }
}

@media only screen and (max-width: 1024px) {
  .header__lang-switcher {
    grid-area: 1/5/span 1/span 1;
    align-self: center;
    justify-self: flex-end;
    margin-bottom: 0;
    padding-right: 0;
    margin-right: -20px;
  }
}

@media only screen and (max-width: 768px) {
  .header__lang-switcher {
    grid-area: unset;
    position: absolute;
    z-index: 2;
    left: 23px;
    top: calc(100% + 40px);
  }
}

@media only screen and (max-width: 576px) {
  .header__lang-switcher {
    top: calc(100% + 40px);
  }
}

.header__logo {
  background: #571927;
  grid-area: 2/1/span 1/span 2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  padding: 30px;
}

.header__logo img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .header__logo {
    grid-area: 1/1/span 2/span 2;
    width: 220px;
    height: 220px;
  }
}

.header__logo.active {
  background: unset;
}

@media only screen and (max-width: 768px) {
  .header__logo {
    width: 78px;
    height: 78px;
    padding: 0;
    background: unset;
    align-self: center;
  }
  .header__logo img {
    width: 100%;
    height: 100%;
  }
}

.footer {
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #47101C;
  color: #DCD6CA;
  padding: 53px 0;
}

@media only screen and (max-width: 576px) {
  .footer {
    padding: 20px 0;
  }
}

.footer__modal {
  width: 1px;
  height: 1px;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.footer__modal.is-open {
  opacity: 1;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(87, 25, 39, 0.7);
  backdrop-filter: blur(20px);
}

.footer__inner {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 75px;
  padding-right: 75px;
}

@media only screen and (max-width: 1024px) {
  .footer__inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.footer__content {
  grid-template-rows: repeat(2, 53px);
}

.footer__logo {
  grid-area: 1/1/span 2/span 2;
  justify-self: flex-start;
  width: 106px;
  height: 106px;
}

@media only screen and (max-width: 1024px) {
  .footer__logo {
    grid-area: 1/1/span 1/span 2;
  }
}

@media only screen and (max-width: 576px) {
  .footer__logo {
    width: 69px;
    height: 69px;
    align-self: flex-end;
  }
}

.footer__logo-img {
  height: 100%;
}

.footer__contacts {
  grid-area: 1/3/span 2/span 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 430px;
}

@media only screen and (max-width: 1024px) {
  .footer__contacts {
    grid-area: 1/3/span 1/span 4;
    justify-self: flex-end;
    width: auto;
  }
}

.footer__links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
  .footer__links {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.footer__mail-link {
  text-decoration: underline;
}

.footer__address {
  text-align: end;
}

.footer__copyright {
  grid-area: 1/10/span 2/-1;
  align-self: center;
  justify-self: flex-end;
  text-align: end;
  width: max-content;
}

@media only screen and (max-width: 1024px) {
  .footer__copyright {
    grid-area: 2/4/span 1/span 3;
  }
}

@media only screen and (max-width: 576px) {
  .footer__copyright {
    grid-area: 2/1/span 1/-1;
    margin-top: 25px;
    justify-self: center;
  }
}

.hero {
  grid-template-rows: 190px repeat(2, 110px) auto;
  height: 566px;
}

@media only screen and (max-width: 1440px) {
  .hero {
    grid-template-rows: 190px 207px !important;
    height: 470px;
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .hero {
    grid-template-rows: 120px 137px !important;
  }
}

.hero-error .hero {
  height: auto;
}

@media only screen and (max-width: 1024px) {
  .hero-error .hero {
    grid-template-rows: 190px 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .hero-error .hero {
    grid-template-rows: 120px 50px !important;
  }
}

.hero-cabinet .hero {
  height: auto;
}

@media only screen and (max-width: 768px) {
  .hero-cabinet .hero {
    grid-template-rows: 120px !important;
  }
}

.hero-front .hero {
  height: 100vh;
}

.hero__top {
  position: relative;
  grid-area: 1/1/span 2/-1;
  background: #47101C;
}

@media only screen and (max-width: 1440px) {
  .hero__top {
    grid-area: 1/1/span 1/-1;
  }
}

.hero__top::before {
  content: '';
  position: absolute;
  width: 140vw;
  height: 100%;
  left: -40vw;
  background: #47101C;
  z-index: -1;
}

.hero__image {
  grid-area: 3/1/span 2/-1;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .hero__image {
    grid-area: 2/1/span 3/-1;
  }
}

@media only screen and (max-width: 1024px) {
  .hero__image {
    grid-area: 2/1/span 1/-1;
  }
  .hero-front .hero__image {
    grid-area: 2/1/span 3/-1;
  }
}

.hero__image img {
  position: absolute;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  left: calc((-100vw + 1340px) / 2);
}

@media only screen and (max-width: 1440px) {
  .hero__image img {
    left: -40px;
    right: -40px;
  }
}

@media only screen and (max-width: 768px) {
  .hero__image img {
    right: -20px;
    left: -20px;
  }
}

.menu {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: #47101C;
    width: 100%;
    height: 100%;
    transition: transform 0.3s, opacity 0.3s;
    transform: translateY(-120%);
  }
  .menu.active {
    opacity: 1;
    transform: translateX(0);
    z-index: 2;
    height: 90%;
  }
}

.menu__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.active .menu__list {
  padding: 190px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  width: 50%;
  margin-left: 35%;
}

@media only screen and (max-width: 1024px) {
  .active .menu__list {
    padding: 200px 0 90px;
  }
}

@media only screen and (max-width: 768px) {
  .active .menu__list {
    padding: 120px 0 60px;
  }
}

@media only screen and (max-width: 1024px) {
  .menu__item {
    transform: translateY(-50vh);
    transition: transform 0.3s;
  }
  .active .menu__item {
    transform: translateY(0);
  }
}

.menu__link {
  color: #DCD6CA;
  font-family: "Playfair", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
}

@media only screen and (max-width: 1024px) {
  .menu__link {
    text-transform: unset;
    font-size: 55px;
  }
}

@media only screen and (max-width: 768px) {
  .menu__link {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1024px) {
  .menu__account {
    order: -1;
  }
}

@media only screen and (max-width: 768px) {
  .menu__account {
    width: 55px;
  }
  .menu__account img {
    width: 100%;
  }
}

.menu__account-button {
  background: transparent;
}

.menu__item:nth-child(1) {
  transition-delay: 0.27s;
}

.menu__item:nth-child(2) {
  transition-delay: 0.24s;
}

.menu__item:nth-child(3) {
  transition-delay: 0.21s;
}

.menu__item:nth-child(4) {
  transition-delay: 0.18s;
}

.menu__item:nth-child(5) {
  transition-delay: 0.15s;
}

.menu__item:nth-child(6) {
  transition-delay: 0.12s;
}

.menu__item:nth-child(7) {
  transition-delay: 0.09s;
}

.menu__item:nth-child(8) {
  transition-delay: 0.06s;
}

.menu__item:nth-child(9) {
  transition-delay: 0.03s;
}

.menu__item:nth-child(10) {
  transition-delay: 0s;
}

.lang-switcher {
  color: #DCD6CA;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
  .lang-switcher {
    margin-bottom: -20px;
  }
}

.lang-switcher::after {
  content: url("../icons/arrow-light.svg");
  position: absolute;
  top: 20px;
  opacity: 1;
  transition: all 0.3s ease-in;
}

.lang-switcher.active::after {
  opacity: 0;
  transform: translateY(50px);
}

@media only screen and (max-width: 768px) {
  .lang-switcher {
    transform: translateY(-50vh);
    transition: transform 0.3s;
    margin-top: -15px;
  }
  .lang-switcher.visible {
    transform: translateY(0);
    margin-top: 5px;
  }
}

.lang-switcher__current {
  position: relative;
}

.lang-switcher__link {
  transition: all 0.3s;
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
}

.active .lang-switcher__link {
  pointer-events: all;
  transform: translateY(0);
  opacity: 1;
}

.section-title {
  background: #DCD6CA;
  min-height: 156px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error .section-title {
  justify-content: flex-end;
  padding: 0 38px;
}

@media only screen and (max-width: 576px) {
  .error .section-title {
    padding: 0 25px;
  }
}

@media only screen and (max-width: 1240px) {
  .cabinet .section-title {
    padding: 15px;
  }
}

@media only screen and (max-width: 1240px) {
  .section-title {
    padding: 38px;
  }
}

@media only screen and (max-width: 1024px) {
  .section-title {
    padding: 30px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .section-title {
    justify-content: center;
    min-height: 91px;
    padding: 18px;
  }
  .events .section-title {
    justify-content: flex-start;
  }
}

.dark .section-title {
  background: #47101C;
}

.section-title__image {
  position: absolute;
  left: 38px;
}

@media only screen and (max-width: 1024px) {
  .section-title__image {
    position: relative;
    left: unset;
  }
}

@media only screen and (max-width: 768px) {
  .section-title__image {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .error .section-title__image {
    position: absolute;
    left: 38px;
  }
}

@media only screen and (max-width: 768px) {
  .error .section-title__image {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .error .section-title__image {
    display: none;
  }
}

.dark .section-title__image svg path {
  fill: #DCD6CA;
}

.section-title__image--add {
  position: absolute;
  right: 38px;
}

@media only screen and (max-width: 768px) {
  .section-title__image--add {
    height: 75%;
    right: 17px;
  }
  .section-title__image--add img {
    height: 100%;
  }
}

.section-title__text {
  display: block;
  font-family: "Playfair", sans-serif;
  font-size: 60px;
  line-height: 60px;
  color: #47101C;
  max-width: 500px;
}

.dark .section-title__text {
  color: #DCD6CA;
}

.error .section-title__text {
  font-size: 90px;
  max-width: unset;
}

@media only screen and (max-width: 1440px) {
  .error .section-title__text {
    font-size: 6vw;
  }
}

@media only screen and (max-width: 576px) {
  .error .section-title__text {
    font-size: 30px;
  }
}

.cabinet .section-title__text {
  font-size: 50px;
  padding: 15px;
}

@media only screen and (max-width: 1440px) {
  .cabinet .section-title__text {
    font-size: 4vw;
    line-height: 1;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet .section-title__text {
    font-size: 8vw;
  }
}

@media only screen and (max-width: 1024px) {
  .section-title__text {
    padding-left: 20px;
    max-width: unset;
  }
}

@media only screen and (max-width: 768px) {
  .section-title__text {
    font-size: 40px;
    line-height: 40px;
    padding-left: 0;
  }
}

.section-subtitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.section-subtitle__image svg {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .section-subtitle__image {
    margin: 0 -40px;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .section-subtitle__image {
    padding-top: 15px;
  }
}

.subtitle-small {
  padding: 30px 0;
}

.title-wide {
  position: relative;
}

.title-wide::before {
  content: '';
  position: absolute;
  top: 0;
  width: 140vw;
  height: 100%;
  left: -40vw;
  background: #47101C;
}

@media only screen and (max-width: 1024px) {
  .title-wide::before {
    display: none;
  }
}

.title-wide__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 156px;
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .title-wide__grid {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
  }
}

.title-wide__title {
  grid-area: 1/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .title-wide__title {
    grid-area: 1/1/span 1/-1;
  }
}

.text-block {
  color: #747474;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 35px;
  text-align: justify;
  font-size: 20px;
}

.light .text-block {
  color: #DCD6CA;
}

@media only screen and (max-width: 768px) {
  .text-block {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .text-block {
    text-align: left;
  }
}

.text-section {
  padding: 40px 0;
}

.text-section__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .text-section__grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.text-section__content {
  grid-area: 1/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .text-section__content {
    grid-area: 1/1/span 1/-1;
  }
}

.arrow-top {
  padding: 90px 0;
  display: flex;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .arrow-top {
    width: calc(100% + 80px);
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .arrow-top {
    padding: 50px 0;
  }
}

.arrow-top__image {
  width: 63px;
  height: 63px;
  border: 1px solid #A4A4A4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EFF4F2;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .arrow-top__image {
    width: 45px;
    height: 45px;
  }
  .arrow-top__image svg {
    height: 18px;
  }
}

.arrow-top__image:hover {
  background: #47101C;
}

.arrow-top__image:hover svg path {
  fill: #DCD6CA;
}

.dark .arrow-top__image {
  background: #47101C;
}

.dark .arrow-top__image svg path {
  fill: #DCD6CA;
}

.dark .arrow-top__image:hover {
  background: #EFF4F2;
}

.dark .arrow-top__image:hover svg path {
  fill: #47101C;
}

.arrow-top::before, .arrow-top::after {
  content: '';
  position: absolute;
  width: calc(50% - 31px);
  top: -50%;
  height: 100%;
  border-bottom: 1px solid #A4A4A4;
}

@media only screen and (max-width: 768px) {
  .arrow-top::before, .arrow-top::after {
    width: calc(50% - 22px);
  }
}

.arrow-top::before {
  left: 0;
}

.arrow-top::after {
  right: 0;
}

.arrows {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .arrows {
    width: 90%;
    margin: 0 auto;
  }
}

.arrows button {
  background: transparent;
}

.arrows button svg {
  fill: #47101C;
  stroke: #47101C;
}

.partners {
  padding-top: 90px;
}

@media only screen and (max-width: 1024px) {
  .partners {
    padding-top: 50px;
  }
}

.partners__content {
  grid-template-rows: 80px minmax(80px, 1fr);
  background: #DCD6CA;
  position: relative;
}

.partners__content::before {
  content: '';
  position: absolute;
  top: 0;
  width: 140vw;
  height: 100%;
  left: -40vw;
  background: #DCD6CA;
  z-index: -1;
}

.partners__title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
}

@media only screen and (max-width: 1024px) {
  .partners__title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .partners__title {
    margin-top: -50px;
  }
}

.partners__slider {
  grid-area: 2/1/span 1/-1;
  position: relative;
  margin-top: 120px;
}

@media only screen and (max-width: 768px) {
  .partners__slider {
    margin-top: 60px;
  }
}

.partners__slider-list {
  justify-content: space-between;
}

.partners__logo {
  display: flex !important;
  justify-content: center;
}

.partners__slider-arrows {
  position: absolute;
  top: 50%;
  width: 106%;
  left: -3%;
}

@media only screen and (max-width: 1440px) {
  .partners__slider-arrows {
    width: 100%;
    left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .partners__slider-arrows {
    display: none;
  }
}

.partners__arrow-top {
  grid-area: 3/1/span 1/-1;
  padding: 90px 0 0;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .partners__arrow-top {
    padding: 40px 0 0;
  }
}

.event-card {
  font-family: "Playfair", sans-serif;
  position: relative;
  height: 285px;
}

.past-section .event-card {
  height: 550px;
}

@media only screen and (max-width: 1920px) {
  .past-section .event-card {
    height: 285px;
  }
}

.tickets .event-card {
  height: 480px;
}

.event-card__sell {
  position: absolute;
  width: 60%;
  left: 20%;
}

.event-card__image {
  height: 80%;
}

@media only screen and (max-width: 1920px) {
  .past-section .event-card__image {
    height: 75%;
  }
}

@media only screen and (max-width: 768px) {
  .past-section .event-card__image {
    height: 80%;
  }
}

.tickets .event-card__image {
  padding-top: 37px;
}

.event-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-card__details {
  position: absolute;
  min-height: 40%;
  width: 75%;
  color: #EFF4F2;
  background: #47101C;
  left: 12.5%;
  bottom: 0;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1920px) {
  .event-card__details {
    width: 90%;
    left: 5%;
  }
}

.past-section .event-card__details {
  min-height: 40%;
}

@media only screen and (max-width: 1920px) {
  .past-section .event-card__details {
    min-height: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .past-section .event-card__details {
    min-height: 40%;
  }
}

.event-card__main-details {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .event-card__main-details {
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
  }
}

.event-card__date {
  display: flex;
  flex-direction: column;
  color: #DCD6CA;
  padding-right: 25px;
}

@media only screen and (max-width: 768px) {
  .event-card__date {
    flex-direction: row;
    align-items: center;
  }
}

.event-card__day {
  font-size: 55px;
  line-height: 55px;
}

.event-card__day span {
  display: none;
}

@media only screen and (max-width: 768px) {
  .event-card__day {
    font-size: 25px;
    line-height: 25px;
  }
  .event-card__day span {
    display: inline;
  }
}

.event-card__rest {
  font-size: 25px;
  line-height: 25px;
}

.event-card__name {
  font-size: 25px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .event-card__name {
    font-size: 19px;
  }
}

.event-card__add-details {
  padding-top: 20px;
}

.event-card__price-title {
  font-size: 23px;
  color: #DCD6CA;
  padding-right: 25px;
}

.event-card__price {
  font-size: 35px;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #EFF4F2;
}

.button {
  background: #47101C;
  min-height: 74px;
  padding: 0 20px;
  border-radius: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  color: #EFF4F2;
  font-family: "Playfair", sans-serif;
  border: 1px solid #47101C;
}

@media only screen and (max-width: 1024px) {
  .button {
    min-height: 65px;
  }
}

.button:hover {
  background: #DCD6CA;
  color: #47101C;
}

.button__link {
  font-size: 40px;
  line-height: 32px;
}

@media only screen and (max-width: 1024px) {
  .button__link {
    font-size: 25px;
  }
}

.section-button {
  margin: 0 auto;
  max-width: 400px;
}

.arrow {
  display: flex;
  justify-content: center;
  position: relative;
}

.arrow__image {
  width: 63px;
  height: 63px;
  border: 1px solid #A4A4A4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EFF4F2;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .arrow__image {
    width: 25px;
    height: 25px;
    border: none;
    background: none;
  }
  .arrow__image svg {
    height: 18px;
  }
}

.arrow__image:hover {
  background: #47101C;
}

@media only screen and (max-width: 768px) {
  .arrow__image:hover {
    background: none;
  }
}

.arrow__image:hover svg path {
  fill: #DCD6CA;
}

@media only screen and (max-width: 768px) {
  .arrow__image:hover svg path {
    fill: #47101C;
  }
}

.bckg {
  position: absolute;
  left: calc((100% - 1699px) / 2);
  z-index: -1;
}

@media only screen and (max-width: 768px) {
  .bckg {
    left: -25%;
  }
  .bckg img {
    width: 120%;
  }
}

.delimiter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .delimiter__image {
    margin: 0 -40px;
  }
}

.delimiter svg {
  width: 100%;
}

.person {
  font-family: "Playfair", sans-serif;
}

.person__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(80px, 1fr);
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .person__grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.person__title {
  grid-area: 2/3/span 2/span 8;
}

@media only screen and (max-width: 1024px) {
  .person__title {
    grid-area: 2/1/span 2/-1;
  }
}

.person__image {
  grid-area: 3/1/span 4/span 7;
}

@media only screen and (max-width: 1440px) {
  .person__image {
    margin-left: -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .person__image {
    grid-area: 3/1/span 4/span 4;
    margin-left: -40px;
  }
}

@media only screen and (max-width: 576px) {
  .person__image {
    grid-area: 3/1/span 3/-1;
    margin: 0 -20px;
  }
}

.person__image img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.person__contacts {
  grid-area: 5/7/span 1/span 4;
  display: flex;
  flex-direction: column;
  background: #DCD6CA;
  z-index: 0;
  padding: 50px 20%;
}

@media only screen and (max-width: 1024px) {
  .person__contacts {
    grid-area: 5/4/span 1/-1;
  }
}

@media only screen and (max-width: 1440px) {
  .person__contacts {
    padding: 10%;
  }
}

@media only screen and (max-width: 1240px) {
  .person__contacts {
    padding: 5%;
  }
}

@media only screen and (max-width: 576px) {
  .person__contacts {
    padding: 25px 35px;
    grid-area: 5/1/span 2/-1;
    margin-bottom: 20px;
  }
}

.person__name {
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 32px;
  color: #3D3D3D;
}

@media only screen and (max-width: 768px) {
  .person__name {
    margin-bottom: 20px;
    font-size: 5vw;
  }
}

@media only screen and (max-width: 576px) {
  .person__name {
    font-size: 40px;
  }
}

.person__tel {
  font-size: 31px;
  margin-bottom: 60px;
  color: #3D3D3D;
}

@media only screen and (max-width: 768px) {
  .person__tel {
    margin-bottom: 20px;
    font-size: 4vw;
  }
}

@media only screen and (max-width: 576px) {
  .person__tel {
    font-size: 31px;
  }
}

.person__mail {
  font-size: 19px;
  color: #47101C;
  text-decoration: underline;
}

@media only screen and (max-width: 576px) {
  .person__mail {
    font-size: 5vw;
  }
}

.person__bckg {
  grid-area: 3/8/span 4/span 5;
  background: #47101C;
  margin-left: -20px;
}

@media only screen and (max-width: 1440px) {
  .person__bckg {
    margin-right: -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .person__bckg {
    grid-area: 3/5/span 4/-1;
    margin-right: -40px;
  }
}

@media only screen and (max-width: 576px) {
  .person__bckg {
    grid-area: 6/1/span 1/-1;
    margin-left: -20px;
  }
}

.person__bckg-image {
  opacity: .1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  color: #3D3D3D;
}

.info__title {
  padding: 60px 0;
  font-size: 40px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .info__title {
    font-size: 25px;
    padding: 40px 0;
  }
}

.info__descr {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .info__descr {
    flex-direction: column;
  }
}

.info__guide {
  display: flex;
}

@media only screen and (max-width: 576px) {
  .info__guide {
    flex-direction: column;
  }
}

.info-details {
  display: flex;
  flex-direction: column;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  line-height: 30px;
  padding-left: 26px;
}

@media only screen and (max-width: 576px) {
  .info-details {
    font-size: 16px;
    padding-left: 0;
  }
}

.info-link {
  color: #47101C;
}

.info-title {
  font-family: "Playfair", sans-serif;
  font-size: 25px;
  max-width: 140px;
}

@media only screen and (max-width: 576px) {
  .info-title {
    font-size: 22px;
    max-width: unset;
    padding-bottom: 20px;
  }
}

.info__guide + .info__guide {
  margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
  .info__guide + .info__guide {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .info__guide + .info__guide {
    margin-top: 35px;
  }
}

.full-gallery {
  position: relative;
}

.full-gallery__images-wrapper {
  width: 100vw;
  margin-left: calc((-100vw + 1340px) / 2);
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1440px) {
  .full-gallery__images-wrapper {
    padding-top: 0;
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 768px) {
  .full-gallery__images-wrapper {
    margin: 0 -20px;
  }
}

.full-gallery__card {
  width: 100%;
}

.full-gallery__image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.full-gallery__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth {
  color: #DCD6CA;
  width: 100%;
  pointer-events: none;
}

.is-open .auth {
  pointer-events: all;
}

@media only screen and (max-width: 768px) {
  .is-open .auth {
    width: 80vw;
  }
}

@media only screen and (max-width: 576px) {
  .is-open .auth {
    width: 100%;
  }
}

.auth__inner {
  max-width: 850px;
  margin: 0 auto;
}

.auth__wrapper {
  position: relative;
}

.auth__close {
  background: transparent;
  position: absolute;
  top: 34px;
  right: 34px;
}

@media only screen and (max-width: 576px) {
  .auth__close {
    top: 20px;
    right: 20px;
  }
  .auth__close svg {
    height: 16px;
    width: 16px;
  }
}

.auth__body {
  background: #47101C;
  padding: 84px;
  box-shadow: 0 0 150px #2E000A;
}

@media only screen and (max-width: 768px) {
  .auth__body {
    padding: 80px 40px;
  }
}

@media only screen and (max-width: 576px) {
  .auth__body {
    height: 100vh;
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.auth__title {
  min-height: 100px;
  font-family: "Playfair", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 50px;
  color: #EFF4F2;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .auth__title {
    font-size: 5vw;
  }
}

@media only screen and (max-width: 576px) {
  .auth__title {
    font-size: 9vw;
    line-height: 1;
  }
}

.auth__form {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 576px) {
  .auth__form {
    height: 100%;
    justify-content: center;
  }
}

.auth__fields {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 45px;
}

@media only screen and (max-width: 768px) {
  .auth__fields {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 576px) {
  .auth__fields {
    padding-bottom: 4vh;
  }
}

.auth__label {
  font-size: 25px;
  text-transform: uppercase;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .auth__label {
    text-align: left;
  }
}

@media only screen and (max-width: 576px) {
  .auth__label {
    font-size: 6vw;
  }
}

.auth__input {
  font-size: 25px;
  background: transparent;
  border-bottom: 1px solid rgba(239, 244, 242, 0.4);
  width: 50%;
  margin-left: 10px;
  padding-left: 10px;
  color: #DCD6CA;
}

.auth__input--phone::-webkit-input-placeholder {
  color: #DCD6CA;
  opacity: .3;
}

@media only screen and (max-width: 768px) {
  .auth__input {
    width: 100%;
    min-height: 60px;
    margin-left: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 576px) {
  .auth__input {
    min-height: 6vh;
  }
}

.auth__select {
  border-top: none;
  border-right: none;
  border-left: none;
  flex-shrink: 0;
}

.auth__submit {
  width: 240px;
  margin: 4vh auto 1vh;
  min-height: 60px;
  border-radius: 40px;
  background: #DCD6CA;
  color: #47101C;
  font-family: "Playfair", sans-serif;
  font-size: 25px;
}

.edit {
  background: url("../icons/edit.svg");
  width: 23px;
  height: 23px;
}

.hero-front__inner {
  position: relative;
}

.values__values-title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
}

@media only screen and (max-width: 1024px) {
  .values__values-title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .values__values-title {
    margin-top: -50px;
  }
}

.values__values-subtitle {
  grid-area: 2/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .values__values-subtitle {
    grid-area: 2/1/span 1/-1;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .values__values-subtitle {
    padding-top: 15px;
  }
}

.values__values-text {
  grid-area: 3/3/span 1/span 8;
  padding: 30px 0 90px 0;
}

@media only screen and (max-width: 1024px) {
  .values__values-text {
    grid-area: 3/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .values__values-text {
    padding: 30px 0;
  }
}

.values__values-image {
  grid-area: 4/1/span 1/-1;
}

@media only screen and (max-width: 1440px) {
  .values__values-image {
    margin: 0 -40px;
  }
}

.values__values-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .values__values-image img {
    width: calc(100% + 40px);
  }
}

.values__meeting-content {
  grid-template-rows: minmax(80px, 1fr);
}

.values__meeting-title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -75px;
  margin-bottom: 140px;
}

@media only screen and (max-width: 1024px) {
  .values__meeting-title {
    grid-area: 1/1/span 1/-1;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .values__meeting-title {
    margin-bottom: 80px;
    margin-top: -50px;
  }
}

.values__meeting-description {
  grid-area: 2/1/span 2/-1;
}

@media only screen and (max-width: 1440px) {
  .values__meeting-description {
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 768px) {
  .values__meeting-description {
    margin: 0 -20px;
  }
}

.values__arrow-top {
  padding: 110px 0 90px;
  width: 100%;
  grid-area: 4/1/span 1/-1;
}

@media only screen and (max-width: 1440px) {
  .values__arrow-top {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 576px) {
  .values__arrow-top {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .club {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .club {
    padding-bottom: 0;
  }
}

.clubhouse {
  color: #DCD6CA;
  font-family: "Playfair", sans-serif;
}

@media only screen and (max-width: 576px) {
  .clubhouse {
    padding-top: 53px;
  }
}

.clubhouse__content {
  grid-template-rows: auto minmax(80px, 1fr) !important;
}

@media only screen and (max-width: 768px) {
  .clubhouse__content {
    display: flex;
    flex-direction: column;
  }
}

.clubhouse__text {
  grid-area: 3/7/span 1/-1;
  padding-left: 40px;
}

@media only screen and (max-width: 1024px) {
  .clubhouse__text {
    grid-area: 3/4/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .clubhouse__text {
    padding-left: 0;
  }
}

.clubhouse__text-title {
  position: relative;
}

.clubhouse__text-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 92px;
  height: 10px;
  background: #47101C;
}

.clubhouse__image {
  grid-area: 3/1/-1/span 6;
}

@media only screen and (max-width: 1440px) {
  .clubhouse__image {
    margin-left: -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .clubhouse__image {
    grid-area: 3/1/-1/span 3;
  }
}

@media only screen and (max-width: 768px) {
  .clubhouse__image {
    margin: 0 -40px;
  }
}

.clubhouse__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.life__content {
  grid-template-rows: auto minmax(80px, 1fr);
}

.life__title {
  grid-area: 1/3/span 1/span 8;
  margin-bottom: 110px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .life__title {
    grid-area: 1/1/span 1/-1;
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .life__title {
    margin-bottom: 20px;
  }
}

.life__images {
  position: relative;
  grid-area: 2/1/span 1/span 6;
}

@media only screen and (max-width: 1024px) {
  .life__images {
    grid-area: 2/1/span 1/-1;
  }
}

.life__images-wrapper {
  display: flex;
  justify-content: space-around;
  position: absolute;
  right: 0;
  width: 50vw;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .life__images-wrapper {
    position: relative;
    width: 100vw;
    left: -40px;
  }
}

@media only screen and (max-width: 768px) {
  .life__images-wrapper {
    left: 0;
  }
}

.life__card {
  width: 100%;
  height: calc(100% + 60px);
}

@media only screen and (max-width: 1024px) {
  .life__card {
    height: 100%;
  }
}

.life__image {
  position: relative;
  width: 100%;
  height: 100%;
}

.life__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.life__text {
  grid-area: 2/7/span 1/-1;
  background: #47101C;
  margin-left: -20px;
  position: relative;
  padding: 120px 0 40px 67px;
}

@media only screen and (max-width: 1024px) {
  .life__text {
    grid-area: 3/1/span 1/-1;
    margin-left: -40px;
    padding: 120px 60px 40px 67px;
  }
}

@media only screen and (max-width: 768px) {
  .life__text {
    margin: 0 -20px;
    padding: 60px 40px 40px;
  }
}

@media only screen and (max-width: 576px) {
  .life__text {
    padding: 60px 30px 30px;
  }
}

.life__text::before {
  content: '';
  position: absolute;
  top: 0;
  width: 140vw;
  height: 100%;
  left: -40vw;
  background: #47101C;
  z-index: -1;
}

@media only screen and (max-width: 1024px) {
  .life__text::before {
    display: none;
  }
}

.life__text::after {
  content: '';
  position: absolute;
  top: 30px;
  left: 67px;
  width: 92px;
  height: 10px;
  background: #DCD6CA;
}

@media only screen and (max-width: 768px) {
  .life__text::after {
    left: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .life__text::after {
    left: 30px;
  }
}

.image-text__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(80px, 1fr);
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .image-text__grid {
    grid-template-rows: 80px 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .image-text__grid {
    display: flex;
    flex-direction: column;
  }
  .image-text__grid.reverse {
    flex-direction: column-reverse;
  }
}

.image-text__image {
  grid-area: 1/1/span 2/span 5;
}

@media only screen and (max-width: 1440px) {
  .image-text__image {
    margin-left: -40px;
  }
}

.image-text__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .image-text__image {
    grid-area: 2/1/span 1/span 2;
    margin-right: -20px;
  }
}

@media only screen and (max-width: 576px) {
  .image-text__image {
    height: 40vh;
  }
}

.image-text__text {
  grid-area: 2/6/span 1/span 7;
  padding: 50px;
  margin-left: -20px;
  background: #DCD6CA;
}

.background .image-text__text {
  background: #47101C;
  color: #DCD6CA !important;
}

@media only screen and (max-width: 1440px) {
  .image-text__text {
    margin-right: -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .image-text__text {
    grid-area: 1/3/span 2/span 5;
  }
}

@media only screen and (max-width: 768px) {
  .image-text__text {
    margin-left: -20px;
    padding: 30px;
  }
}

.image-title__grid {
  grid-template-rows: 400px 80px;
}

@media only screen and (max-width: 1024px) {
  .image-title__grid {
    grid-template-rows: 600px 80px;
  }
}

@media only screen and (max-width: 768px) {
  .image-title__grid {
    grid-template-rows: 400px 50px;
  }
}

.image-title__image {
  grid-area: 1/1/span 2/-1;
}

@media only screen and (max-width: 1440px) {
  .image-title__image {
    margin: 0 -40px;
  }
}

.image-title__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-title__title {
  grid-area: 2/3/span 2/span 8;
}

@media only screen and (max-width: 1024px) {
  .image-title__title {
    grid-area: 2/1/span 2/-1;
  }
}

.guests-info__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 80px) 1fr;
  grid-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .guests-info__grid {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
  }
}

.guests-info__title {
  grid-area: 1/3/span 2/span 8;
}

@media only screen and (max-width: 1024px) {
  .guests-info__title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__title {
    margin-bottom: -50px;
  }
}

.guests-info__bg {
  background: #47101C;
  grid-area: 2/1/span 3/-1;
}

@media only screen and (max-width: 1440px) {
  .guests-info__bg {
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__bg {
    grid-area: 2/1/span 4/-1;
  }
}

.guests-info__text {
  padding: 60px 45px 60px 0;
}

@media only screen and (max-width: 1024px) {
  .guests-info__text {
    padding: 0;
  }
}

.guests-info__text-top {
  grid-area: 3/3/span 1/-1;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .guests-info__text-top {
    grid-area: 3/1/span 1/-1;
    padding: 50px 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__text-top {
    padding: 70px 0 30px;
  }
}

.guests-info__text-top::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  border-bottom: 1px solid #DCD6CA;
}

@media only screen and (max-width: 1024px) {
  .guests-info__text-top::before {
    display: none;
  }
}

.guests-info__text-bottom {
  grid-area: 4/5/span 1/-1;
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 1024px) {
  .guests-info__text-bottom {
    grid-area: 4/3/span 1/-1;
    padding: 0 0 60px 60px;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__text-bottom {
    grid-area: 5/1/span 1/-1;
    padding: 20px 0;
  }
}

.guests-info__amount {
  grid-area: 4/1/span 1/span 4;
  display: flex;
  align-items: flex-end;
  padding-bottom: 60px;
  color: #DCD6CA;
}

@media only screen and (max-width: 1024px) {
  .guests-info__amount {
    grid-area: 4/1/span 1/span 2;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__amount {
    grid-area: 4/1/span 1/-1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.guests-info__amount-description {
  font-size: 40px;
  line-height: 40px;
  position: relative;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .guests-info__amount-description {
    font-size: 32px;
    line-height: 30px;
  }
}

.guests-info__amount-description::before {
  content: '';
  position: absolute;
  top: -45px;
  left: 0;
  width: 92px;
  height: 10px;
  background: #DCD6CA;
}

@media only screen and (max-width: 768px) {
  .guests-info__amount-description::before {
    top: -25px;
  }
}

.guests-info__amount-value {
  font-size: 160px;
  line-height: 120px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .guests-info__amount-value {
    font-size: 240px;
    line-height: unset;
    margin: -60px 0 60px;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__amount-value {
    font-size: 165px;
    margin: -60px 40px 0 0;
  }
}

.guests-info__amount-item {
  width: 50%;
}

@media only screen and (max-width: 1024px) {
  .guests-info__amount-item {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .guests-info__amount-item {
    width: 50%;
  }
}

.about-section__title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
}

@media only screen and (max-width: 1024px) {
  .about-section__title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .about-section__title {
    margin-top: -50px;
  }
}

.about-section__subtitle {
  grid-area: 2/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .about-section__subtitle {
    grid-area: 2/1/span 1/-1;
  }
}

.about-section__text {
  grid-area: 3/3/span 1/span 8;
  padding: 30px 0 90px 0;
}

@media only screen and (max-width: 1024px) {
  .about-section__text {
    grid-area: 3/1/span 1/-1;
    padding: 60px 0;
  }
}

@media only screen and (max-width: 576px) {
  .about-section__text {
    padding: 30px 0;
  }
}

.about-section__text-accent {
  grid-area: 4/3/span 2/span 8;
  padding: 50px 60px;
  background: #47101C;
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  .about-section__text-accent {
    grid-area: 4/1/span 1/-1;
    padding: 30px 45px;
    margin: 0 -40px;
    z-index: 0;
  }
}

.about-section__image {
  grid-area: 5/1/span 2/-1;
  margin-bottom: 90px;
}

@media only screen and (max-width: 1440px) {
  .about-section__image {
    margin: 0 -40px 90px;
  }
}

@media only screen and (max-width: 1024px) {
  .about-section__image {
    margin: 0 -40px 50px;
  }
}

@media only screen and (max-width: 768px) {
  .about-section__image {
    margin: 0 -40px;
  }
}

.about-section__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-section__description {
  grid-area: 7/1/span 2/-1;
  margin-top: 40px;
}

@media only screen and (max-width: 1440px) {
  .about-section__description {
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 768px) {
  .about-section__description {
    margin: 0 -20px;
  }
}

.about-section__arrow-top {
  padding: 110px 0 90px;
  width: 100%;
  grid-area: 9/1/span 1/-1;
}

@media only screen and (max-width: 1024px) {
  .about-section__arrow-top {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 576px) {
  .about-section__arrow-top {
    display: none;
  }
}

.invitations__content {
  grid-template-rows: minmax(80px, 1fr);
}

@media only screen and (max-width: 768px) {
  .invitations__content {
    display: flex;
    flex-direction: column;
  }
}

.invitations__text-top {
  grid-area: 1/7/span 1/span 6;
  padding: 0 0 160px 55px;
}

.invitations__text-top p {
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .invitations__text-top {
    grid-area: 1/4/span 1/span 3;
    padding: 0 0 45px 45px;
  }
}

@media only screen and (max-width: 768px) {
  .invitations__text-top {
    padding: 20px 0;
  }
}

.invitations__text-bottom {
  grid-area: 2/1/span 1/span 6;
  padding: 160px 55px 0 0;
}

.invitations__text-bottom p {
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .invitations__text-bottom p {
    text-align: left;
  }
}

@media only screen and (max-width: 1024px) {
  .invitations__text-bottom {
    grid-area: 2/1/span 1/span 3;
    padding: 45px 45px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .invitations__text-bottom {
    padding: 20px 0;
  }
}

.invitations__image-top {
  grid-area: 1/1/span 1/span 6;
  margin-right: -10px;
}

@media only screen and (max-width: 1440px) {
  .invitations__image-top {
    margin-left: -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .invitations__image-top {
    grid-area: 1/1/span 1/span 3;
  }
}

@media only screen and (max-width: 768px) {
  .invitations__image-top {
    margin: 0 -20px;
  }
}

.invitations__image-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.invitations__image-bottom {
  grid-area: 2/7/span 1/span 6;
  margin-left: -10px;
}

@media only screen and (max-width: 1440px) {
  .invitations__image-bottom {
    margin-right: -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .invitations__image-bottom {
    grid-area: 2/4/span 1/span 3;
  }
}

@media only screen and (max-width: 768px) {
  .invitations__image-bottom {
    margin: 0 -20px;
  }
}

.invitations__image-bottom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.events-section {
  padding-top: 120px;
}

@media only screen and (max-width: 1024px) {
  .events-section {
    padding-top: 60px;
  }
}

.events-section__content {
  grid-template-rows: minmax(80px, 1fr) !important;
}

@media only screen and (max-width: 768px) {
  .events-section__content {
    grid-template-rows: minmax(50px, 1fr) !important;
  }
}

.events-section__title {
  grid-area: 1/3/span 2/span 8;
}

@media only screen and (max-width: 1024px) {
  .events-section__title {
    grid-area: 1/1/span 2/-1;
  }
}

.events-section__events-block {
  grid-area: 2/1/span 1/-1;
  padding: 120px 60px 60px;
  background: #47101C;
}

@media only screen and (max-width: 1440px) {
  .events-section__events-block {
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 1024px) {
  .events-section__events-block {
    padding: 120px 50px 60px;
  }
}

.events-section__events-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 60px;
}

@media only screen and (max-width: 1024px) {
  .events-section__events-list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .events-section__events-list {
    display: flex;
    flex-direction: column;
  }
}

.events-section__item {
  color: #EFF4F2;
  font-family: "Playfair", sans-serif;
  font-size: 28px;
  min-height: 180px;
  position: relative;
  padding-left: 30px;
}

@media only screen and (max-width: 768px) {
  .events-section__item {
    min-height: unset;
  }
}

.events-section__item::before {
  content: url("../img/decor.svg");
  position: absolute;
  left: -10px;
}

.events-section__gallery {
  grid-area: 5/1/span 1/-1;
  position: relative;
  width: 100vw;
  left: calc((-100vw + 1340px) / 2);
}

@media only screen and (max-width: 1440px) {
  .events-section__gallery {
    left: -40px;
  }
}

@media only screen and (max-width: 768px) {
  .events-section__gallery {
    left: -20px;
  }
}

.events-section__images-wrapper {
  display: flex;
  justify-content: space-around;
}

.events-section__card {
  width: 100%;
}

.events-section__image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.events-section__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-top__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(80px, 1fr);
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .image-top__grid {
    display: flex;
    flex-direction: column;
  }
}

.image-top__text {
  grid-area: 1/3/span 2/span 8;
  padding: 50px 60px;
  background: #47101C;
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  .image-top__text {
    padding: 30px 45px;
    margin: 0 -40px;
    z-index: 0;
  }
}

.image-top__image {
  grid-area: 2/1/span 2/-1;
  margin-bottom: 90px;
}

@media only screen and (max-width: 1440px) {
  .image-top__image {
    margin: 0 -40px 90px;
  }
}

@media only screen and (max-width: 1024px) {
  .image-top__image {
    margin: 0 -40px 50px;
  }
}

@media only screen and (max-width: 768px) {
  .image-top__image {
    margin: 0 -40px;
  }
}

.image-top__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slogan {
  font-family: "Playfair", sans-serif;
}

.slogan__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .slogan__grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.slogan__text {
  position: absolute;
  top: 33%;
  width: 100%;
  padding: 0 20%;
  text-align: center;
}

.slogan__text h3 {
  font-size: 40px !important;
}

@media only screen and (max-width: 1240px) {
  .slogan__text h3 {
    font-size: 3vw !important;
  }
}

@media only screen and (max-width: 1024px) {
  .slogan__text h3 {
    font-size: 6vw !important;
  }
}

@media only screen and (max-width: 1240px) {
  .slogan__text {
    top: 28%;
  }
}

@media only screen and (max-width: 1024px) {
  .slogan__text {
    padding: 0 25%;
  }
}

.slogan__decor {
  grid-column: 4/span 6;
  position: relative;
}

.slogan__decor img {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .slogan__decor {
    grid-column: 1/-1;
  }
}

.text-main {
  font-family: "Playfair", sans-serif;
  color: #3D3D3D;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  padding: 80px 0;
}

@media only screen and (max-width: 768px) {
  .text-main {
    padding: 40px 0;
  }
}

.text-main__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
}

@media only screen and (max-width: 1024px) {
  .text-main__grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.text-main__text {
  grid-area: 1/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .text-main__text {
    grid-area: 1/1/span 1/-1;
  }
}

.club-open {
  font-family: "Playfair", sans-serif;
}

.club-open__content {
  color: #47101C;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
}

@media only screen and (max-width: 576px) {
  .club-open__content {
    padding: 40px 0;
  }
}

.club-open__content span {
  text-align: center;
}

.club-open__text {
  font-size: 40px;
}

@media only screen and (max-width: 576px) {
  .club-open__text {
    font-size: 25px;
  }
}

.club-open__number {
  font-size: 240px;
  line-height: 175px;
}

@media only screen and (max-width: 576px) {
  .club-open__number {
    font-size: 170px;
    line-height: 154px;
  }
}

.events {
  font-family: "Playfair", sans-serif;
}

@media only screen and (max-width: 1024px) {
  .events {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .events {
    padding-bottom: 30px;
  }
}

.events__content {
  grid-template-rows: minmax(80px, 1fr);
}

.events__title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
}

@media only screen and (max-width: 1024px) {
  .events__title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .events__title {
    margin-top: -50px;
  }
}

.events__future {
  grid-area: 2/1/span 1/-1;
}

.events__past {
  grid-area: 3/1/span 1/-1;
}

.events__calendar {
  grid-area: 4/1/span 1/-1;
  margin: 0 auto;
  padding: 60px;
}

@media only screen and (max-width: 1024px) {
  .events__calendar {
    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .events__calendar {
    padding: 0 0 30px;
  }
}

.events__calendar:hover svg path {
  transition: all 0.3s ease-in-out;
  opacity: .9;
}

.events__button {
  background: transparent;
}

.events__link {
  grid-area: 5/1/span 1/-1;
  width: 400px;
  margin: 0 auto;
}

@media only screen and (max-width: 576px) {
  .events__link {
    width: 100%;
  }
}

.events__arrow {
  grid-area: 6/1/span 1/-1;
  padding: 30px;
}

@media only screen and (max-width: 1024px) {
  .events__arrow {
    display: none;
  }
}

.events-future__title {
  padding: 60px 0;
}

@media only screen and (max-width: 1024px) {
  .events-future__title {
    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .events-future__title {
    padding: 20px 0;
  }
}

.events-future__slider {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .events-future__slider {
    margin: 0 -40px;
  }
}

.events-future__slider-arrows {
  display: none;
}

@media only screen and (max-width: 768px) {
  .events-future__slider-arrows {
    display: block;
    padding: 30px 0;
  }
}

.events-future__card {
  width: 50%;
  max-width: 600px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1024px) {
  .events-future__card {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .events-future__card {
    margin: 0 20px;
    width: 100vw !important;
    max-width: unset;
  }
}

.events-future__link {
  max-width: 400px;
  margin: 0 auto;
}

.events-past__title {
  padding: 60px 0;
}

@media only screen and (max-width: 1024px) {
  .events-past__title {
    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .events-past__title {
    padding: 20px 0;
  }
}

.events-past__slider {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin-left: calc((-100vw + 1340px) / 2);
}

@media only screen and (max-width: 1440px) {
  .events-past__slider {
    margin-left: -40px;
    width: calc(100vw - 17px);
  }
}

@media only screen and (max-width: 768px) {
  .events-past__slider {
    width: 100vw;
    margin-right: -40px;
  }
}

.events-past__slider-arrows {
  display: none;
}

@media only screen and (max-width: 768px) {
  .events-past__slider-arrows {
    display: block;
    padding: 30px 0;
  }
}

.events-past__card {
  width: 25%;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1920px) {
  .events-past__card {
    width: 33.33%;
  }
}

@media only screen and (max-width: 1024px) {
  .events-past__card {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .events-past__card {
    margin: 0 20px;
    width: 100vw !important;
    max-width: unset;
  }
}

.events-past .slick-list {
  margin-right: -20px;
}

.events-button {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.events-button__button {
  background: transparent;
}

.calendar {
  color: #3D3D3D;
}

@media only screen and (max-width: 768px) {
  .calendar {
    width: 80vw;
  }
}

.calendar__wrapper {
  position: relative;
}

.calendar__close {
  background: transparent;
  position: absolute;
  top: -45px;
  right: -45px;
}

@media only screen and (max-width: 768px) {
  .calendar__close {
    top: -7vw;
    right: -7vw;
  }
}

@media only screen and (max-width: 576px) {
  .calendar__close svg {
    height: 16px;
    width: 16px;
  }
}

.calendar__body {
  background: #DCD6CA;
  padding: 84px;
}

@media only screen and (max-width: 768px) {
  .calendar__body {
    padding: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .calendar__body {
    padding: 20px;
  }
}

.calendar__title {
  min-height: 130px;
  font-family: "Playfair", sans-serif;
  font-size: 40px;
  line-height: 50px;
  color: #47101C;
  background: #EFF4F2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -33%;
}

@media only screen and (max-width: 768px) {
  .calendar__title {
    font-size: 5vw;
    min-height: 65px;
    margin-top: -75px;
  }
}

@media only screen and (max-width: 576px) {
  .calendar__title {
    font-size: 7vw;
    margin-top: -55px;
  }
}

.calendar__labels {
  display: flex;
  justify-content: space-between;
  padding: 48px 0 35px 0;
}

@media only screen and (max-width: 768px) {
  .calendar__labels {
    padding: 32px 0;
  }
}

@media only screen and (max-width: 576px) {
  .calendar__labels {
    padding: 24px 0;
  }
}

.calendar__grid {
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 24px;
  font-weight: 700;
}

.calendar__week {
  width: 100%;
}

.calendar__week td {
  border: 1px solid rgba(112, 128, 143, 0.3);
}

.calendar__day-wrapper {
  position: relative;
  width: calc(100% / 7);
  min-width: 65px;
  color: #3D3D3D;
  font-weight: 700;
}

.calendar__day-wrapper.prev-month, .calendar__day-wrapper.next-month {
  opacity: .2;
}

.calendar__day-wrapper.current-month__past-days {
  opacity: .6;
}

.calendar__day-wrapper.current-month {
  background: #E7E1D6;
}

.calendar__day-wrapper.today {
  color: #47101C;
}

.calendar__day-wrapper.event-day {
  background-color: #47101C;
  color: #DCD6CA;
  cursor: pointer;
}

.calendar__day-wrapper.event-day:hover .calendar__event-tip {
  opacity: 1;
  pointer-events: all;
}

.calendar__day-wrapper.event-day::before {
  content: '';
  position: absolute;
  opacity: .3;
  width: 100%;
  height: 100%;
  background: url("../img/round_light.svg") no-repeat center;
  background-size: 80%;
  left: 1px;
}

@media only screen and (max-width: 576px) {
  .calendar__day-wrapper.event-day::before {
    left: 0;
    background-size: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .calendar__day-wrapper {
    min-width: unset;
  }
}

.calendar__event-tip {
  position: absolute;
  z-index: 3;
  bottom: calc(100% + 15px);
  left: -150%;
  width: 400%;
  min-height: 100%;
  color: #EFF4F2;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 28px;
  background: #47101C;
  border-radius: 10px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media only screen and (max-width: 768px) {
  .calendar__event-tip {
    padding: 12px;
  }
}

@media only screen and (max-width: 576px) {
  .calendar__event-tip {
    font-size: 12px;
  }
}

.calendar__event-tip::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 17px solid #47101C;
  left: calc(50% - 15px);
  bottom: -15px;
}

.calendar__day {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.today .calendar__day {
  border: 1px solid #8D6F74;
  margin: -1px;
  width: calc(100% + 2px);
}

.calendar__day-number {
  position: absolute;
  font-size: 24px;
  text-align: center;
  padding-top: 30%;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .calendar__day-number {
    padding-top: 37%;
    font-size: 19px;
  }
}

@media only screen and (max-width: 576px) {
  .calendar__day-number {
    padding-top: 30%;
    font-size: 17px;
  }
}

.label {
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.label__button {
  background: transparent;
}

@media only screen and (max-width: 576px) {
  .label__button svg {
    height: 10px;
  }
}

.label__text {
  font-size: 22px;
  font-weight: 700;
  color: #3D3D3D;
}

@media only screen and (max-width: 576px) {
  .label__text {
    font-size: 16px;
  }
}

.tickets {
  font-family: "Playfair", sans-serif;
}

.tickets__content {
  grid-template-rows: minmax(80px, 1fr);
}

.tickets__title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
}

@media only screen and (max-width: 1024px) {
  .tickets__title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .tickets__title {
    margin-top: -50px;
  }
}

.tickets__list {
  grid-area: 2/1/span 1/-1;
  padding: 74px 0;
}

@media only screen and (max-width: 768px) {
  .tickets__list {
    padding: 36px 0 0 0;
  }
}

.tickets__slider {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .tickets__slider {
    margin: 0 -40px;
  }
}

.tickets__card {
  width: 50%;
  max-width: 600px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1024px) {
  .tickets__card {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .tickets__card {
    margin: 0 20px;
    width: 100vw !important;
    max-width: unset;
  }
}

.tickets__slider-arrows {
  display: none;
}

@media only screen and (max-width: 768px) {
  .tickets__slider-arrows {
    display: block;
    padding: 30px 0;
  }
}

.contacts {
  font-family: "Playfair", sans-serif;
  padding-bottom: 106px;
}

@media only screen and (max-width: 768px) {
  .contacts {
    padding-bottom: 40px;
  }
}

.contacts__grid {
  grid-template-rows: minmax(80px, 1fr);
}

.contacts__title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
  margin-bottom: 111px;
}

@media only screen and (max-width: 1440px) {
  .contacts__title {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .contacts__title {
    grid-area: 1/1/span 1/-1;
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 768px) {
  .contacts__title {
    margin-top: -50px;
    margin-bottom: 50px;
  }
}

.contacts__person {
  grid-area: 2/1/span 1/-1;
}

.contacts__info {
  grid-area: 3/1/span 1/-1;
}

.contacts__map {
  grid-area: 7/1/span 1/-1;
}

.map {
  color: #3D3D3D;
  margin: 40px 0;
}

.map__wrapper {
  padding: 40px;
  background: #EFF4F2;
}

@media only screen and (max-width: 576px) {
  .map__wrapper {
    padding: 20px;
  }
}

.map__container {
  padding-bottom: 33%;
}

@media only screen and (max-width: 1024px) {
  .map__container {
    padding-bottom: 50%;
  }
}

@media only screen and (max-width: 576px) {
  .map__container {
    padding-bottom: 100%;
  }
}

.content {
  padding-bottom: 70px;
}

@media only screen and (max-width: 1024px) {
  .content {
    padding-bottom: 30px;
  }
}

.content__grid {
  grid-template-rows: auto;
}

.content__title {
  grid-area: 1/3/span 1/span 8;
  margin-top: -80px;
}

@media only screen and (max-width: 1024px) {
  .content__title {
    grid-area: 1/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .content__title {
    margin-top: -50px;
  }
}

.content__event-title {
  grid-area: 2/3/span 1/span 8;
  font-family: "Playfair", sans-serif;
  font-size: 40px;
  line-height: 50px;
  color: #3D3D3D;
  text-align: center;
  padding: 60px 0;
}

@media only screen and (max-width: 1024px) {
  .content__event-title {
    grid-area: 2/1/span 1/-1;
  }
}

@media only screen and (max-width: 768px) {
  .content__event-title {
    padding: 30px 0;
  }
}

.content__entering {
  grid-area: 3/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .content__entering {
    grid-area: 3/1/span 1/-1;
  }
}

.content__image {
  grid-area: 4/1/span 1/-1;
  margin: 60px 0;
}

.content__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1440px) {
  .content__image {
    margin: 60px -40px;
  }
}

@media only screen and (max-width: 768px) {
  .content__image {
    margin: 30px -40px;
  }
}

.content__content {
  grid-area: 5/3/span 1/span 8;
}

@media only screen and (max-width: 1024px) {
  .content__content {
    grid-area: 5/1/span 1/-1;
  }
}

.content__arrow {
  padding: 110px 0 90px;
  width: 100%;
  grid-area: 6/1/span 1/-1;
}

@media only screen and (max-width: 1024px) {
  .content__arrow {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 576px) {
  .content__arrow {
    display: none;
  }
}

.content__footer {
  grid-area: 7/1/span 1/-1;
}

@media only screen and (max-width: 1024px) {
  .content__footer {
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 768px) {
  .content__footer {
    margin: 0 -20px;
  }
}

.content__events {
  display: flex;
  justify-content: space-between;
}

.content__events.flex-end {
  justify-content: flex-end;
}

.content__event-card {
  width: 50%;
}

.content__link {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 1024px) {
  .content__link {
    padding: 30px 40px 20px;
  }
}

@media only screen and (max-width: 768px) {
  .content__link {
    padding: 30px 15px 20px;
  }
}

.content__link span {
  font-family: "Playfair", sans-serif;
}

.content__link .arrow {
  margin-inline-end: 40px;
}

@media only screen and (max-width: 768px) {
  .content__link .arrow {
    margin-inline-end: 10px;
  }
}

.content__link-main {
  display: flex;
  align-items: center;
}

.content__link-main span {
  color: #3D3D3D;
  font-size: 25px;
}

@media only screen and (max-width: 768px) {
  .content__link-main span {
    font-size: 19px;
  }
}

.content__link-main .arrow-next {
  transform: rotate(180deg);
}

.content__next-link .content__link-main {
  flex-direction: row-reverse;
}

.content__next-link span {
  text-align: right;
}

.content__link-date {
  display: none;
  padding-top: 25px;
  color: #47101C;
  font-size: 25px;
}

@media only screen and (max-width: 768px) {
  .content__link-date {
    display: block;
  }
}

.content__link-title {
  display: none;
  color: #47101C;
  font-size: 19px;
}

@media only screen and (max-width: 768px) {
  .content__link-title {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .content__event {
    display: none;
  }
}

.content__events-button {
  display: flex;
  justify-content: center;
  margin-top: -70px;
}

@media only screen and (max-width: 1024px) {
  .content__events-button {
    margin-top: 0;
  }
}

@media only screen and (max-width: 576px) {
  .content__events-button {
    display: none;
  }
}

.error {
  flex-grow: 1;
}

.error__grid {
  grid-template-rows: auto;
}

@media only screen and (max-width: 1024px) {
  .error__grid {
    grid-template-rows: 1fr 3fr;
  }
}

@media only screen and (max-width: 576px) {
  .error__grid {
    grid-template-rows: 1fr 2fr;
  }
}

.error__title {
  grid-area: 1/3/span 1/span 8;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .error__title {
    grid-area: 1/1/span 1/-1;
  }
}

.error__title-link {
  position: absolute;
  top: 64px;
  left: 60px;
  font-size: 25px;
  color: #47101C;
  display: flex;
}

@media only screen and (max-width: 1440px) {
  .error__title-link {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 1024px) {
  .error__title-link {
    top: 65px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .error__title-link {
    top: 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .error__title-link {
    left: 25px;
    top: 25px;
    font-size: 4vw;
    flex-direction: column;
  }
}

.error__link-decor {
  width: 80px;
}

@media only screen and (max-width: 1440px) {
  .error__link-decor {
    width: 70px;
  }
}

@media only screen and (max-width: 576px) {
  .error__link-decor {
    width: 40px;
  }
}

.error__content {
  grid-area: 2/3/span 1/span 4;
  align-self: center;
}

@media only screen and (max-width: 1440px) {
  .error__content {
    grid-area: 2/3/span 1/span 6;
  }
}

@media only screen and (max-width: 1024px) {
  .error__content {
    grid-area: 2/2/span 1/6;
  }
}

@media only screen and (max-width: 768px) {
  .error__content {
    grid-area: 2/1/span 1/-1;
  }
}

.error__subtitle {
  margin-left: -20px;
}

.error__subtitle .subtitle-small {
  padding: 15px 0;
}

@media only screen and (max-width: 768px) {
  .error__subtitle {
    margin-left: 0;
  }
}

.error__text {
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #3D3D3D;
  line-height: 25px;
}

.error__link {
  color: #47101C;
  text-decoration: underline;
}

.cabinet {
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .cabinet {
    padding: 5vh 0;
  }
}

.cabinet__grid {
  grid-template-rows: auto;
}

.cabinet__title {
  grid-area: 1/3/span 1/span 8;
  position: relative;
  margin-top: -190px;
}

@media only screen and (max-width: 1024px) {
  .cabinet__title {
    grid-area: 1/1/span 1/-1;
    margin-top: -150px;
  }
}

@media only screen and (max-width: 768px) {
  .cabinet__title {
    margin-top: 0;
  }
}

.cabinet__content {
  grid-area: 2/4/span 1/span 6;
  align-self: center;
}

@media only screen and (max-width: 1440px) {
  .cabinet__content {
    grid-area: 2/3/span 1/span 8;
  }
}

@media only screen and (max-width: 1024px) {
  .cabinet__content {
    grid-area: 2/2/span 1/6;
  }
}

@media only screen and (max-width: 768px) {
  .cabinet__content {
    grid-area: 2/1/span 1/-1;
  }
}

.cabinet__form {
  color: #3D3D3D;
  display: flex;
  flex-direction: column;
  padding: 5vh 0;
}

@media only screen and (max-width: 1024px) {
  .cabinet__form {
    padding: 10vh 0;
  }
}

@media only screen and (max-width: 768px) {
  .cabinet__form {
    padding: 5vh 0 0;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet__form {
    height: 100%;
    justify-content: center;
  }
}

.cabinet__fields {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3vh;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .cabinet__fields {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet__fields {
    padding-bottom: 3vh;
  }
}

.cabinet__label {
  font-size: 25px;
  text-transform: uppercase;
  text-align: right;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .cabinet__label {
    text-align: left;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet__label {
    font-size: 5vw;
  }
}

.cabinet__input {
  font-size: 25px;
  background: transparent;
  border-bottom: 1px solid rgba(61, 61, 61, 0.4);
  width: 50%;
  margin-left: 10px;
  padding-left: 10px;
  color: #3D3D3D;
}

@media only screen and (max-width: 768px) {
  .cabinet__input {
    width: calc(100% - 30px);
    min-height: 60px;
    margin-left: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet__input {
    min-height: 5vh;
    font-size: 3vh;
  }
}

.cabinet__edit {
  width: 23px;
  height: 23px;
  align-self: flex-end;
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .cabinet__edit {
    position: absolute;
    bottom: 3vh;
  }
}

.cabinet__select {
  border-top: none;
  border-right: none;
  border-left: none;
  flex-shrink: 0;
  margin-right: 38px;
}

.cabinet__control {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

@media only screen and (max-width: 1024px) {
  .cabinet__control {
    margin-top: 5vh;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet__control {
    flex-direction: column;
  }
}

.cabinet__button {
  width: 45%;
  min-height: 60px;
  border-radius: 40px;
  background: #DCD6CA;
  color: #47101C;
  font-family: "Playfair", sans-serif;
  font-size: 25px;
  line-height: 30px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.cabinet__button:hover {
  background: #47101C;
  color: #DCD6CA;
}

@media only screen and (max-width: 1440px) {
  .cabinet__button {
    width: 30%;
  }
}

@media only screen and (max-width: 1024px) {
  .cabinet__button {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .cabinet__button {
    width: 50%;
    min-height: 80px;
  }
}

@media only screen and (max-width: 576px) {
  .cabinet__button {
    width: 100%;
  }
}

.cabinet__button + .cabinet__button {
  margin-left: 10%;
}

@media only screen and (max-width: 576px) {
  .cabinet__button + .cabinet__button {
    margin-left: 0;
    margin-top: 10%;
  }
}
