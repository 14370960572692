.text-block {
  color: $darkGrey;
  font-family: $contentFont;
  line-height: 35px;
  text-align: justify;
  font-size: 20px;
  .light & {
    color: $lightGrey; }
  @include sm {
    font-size: 16px;
    line-height: 20px; }
  @include md {
    text-align: left; } }

