.arrow {
  display: flex;
  justify-content: center;
  position: relative;
  &__image {
    width: 63px;
    height: 63px;
    border: 1px solid #A4A4A4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    transition: all $tr ease-in-out;
    @include sm {
      width: 25px;
      height: 25px;
      border: none;
      background: none;
      & svg {
        height: 18px; } }

    &:hover {
      background: $main;
      @include sm {
        background: none; }
      & svg {
        & path {
          fill: $lightGrey;
          @include sm {
            fill: $main; } } } } } }
