.contacts {
  font-family: $mainFont;
  padding-bottom: 106px;
  @include sm {
    padding-bottom: 40px; }

  &__grid {
    grid-template-rows: minmax(80px, 1fr); }

  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    margin-bottom: 111px;
    @include xl {
      margin-bottom: 30px; }
    @include md {
      grid-area: 1/1/span 1/-1;
      margin-bottom: 90px; }
    @include sm {
      margin-top: -50px;
      margin-bottom: 50px; } }

  &__person {
    grid-area: 2/1/span 1/-1; }

  &__info {
    grid-area: 3/1/span 1/-1; }

  &__map {
   grid-area: 7/1/span 1/-1; } }
