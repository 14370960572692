.text-section {
  padding: 40px 0;
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    @include md {
      grid-template-columns: repeat(6, 1fr); } }
  &__content {
    grid-area: 1/3/span 1/span 8;
    @include md {
      grid-area: 1/1/span 1/-1; } } }
