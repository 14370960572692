.menu {
  display: flex;
  justify-content: space-between;
  @include md {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: $main;
    width: 100%;
    height: 100%;
    transition: transform $tr, opacity $tr;
    transform: translateY(-120%);
    &.active {
      opacity: 1;
      transform: translateX(0);
      z-index: 2;
      height: 90%; } }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    .active & {
      padding: 190px 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      height: 100%;
      width: 50%;
      margin-left: 35%;
      @include md {
        padding: 200px 0 90px; }
      @include sm {
        padding: 120px 0 60px; } } }

  &__item {
    @include md {
      transform: translateY(-50vh);
      transition: transform $tr;
      .active & {
        transform: translateY(0); } } }

  &__link {
    color: $lightGrey;
    font-family: $mainFont;
    text-transform: uppercase;
    font-size: 15px;
    @include md {
      text-transform: unset;
      font-size: 55px; }
    @include sm {
      font-size: 35px; } }

  &__account {
    @include md {
      order: -1; }
    @include sm {
      width: 55px;
      & img {
        width: 100%; } } }

  &__account-button {
    background: transparent; } }

@for $i from 1 through 10 {
  .menu__item:nth-child(#{$i}) {
    transition-delay: $tr - $i * 0.03s; } }

