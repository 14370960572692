.title-wide {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 140vw;
    height: 100%;
    left: -40vw;
    background: $main;
    @include md {
      display: none; } }

  &__grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: 156px;
    grid-column-gap: $gap;
    @include md {
      grid-template-columns: repeat(6,1fr);
      grid-template-rows: auto; } }

  &__title {
   grid-area: 1/3/span 1/span 8;
   @include md {
     grid-area: 1/1/span 1/-1; } } }

