.person {
  font-family: $mainFont;
  &__grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: minmax(80px,1fr);
    grid-column-gap: 20px;
    @include md {
      grid-template-columns: repeat(6,1fr); } }

  &__title {
    grid-area: 2/3/span 2/span 8;
    @include md {
      grid-area: 2/1/span 2/-1; } }

  &__image {
    grid-area: 3/1/span 4/span 7;
    @include xl {
      margin-left: -40px; }
    @include md {
      grid-area: 3/1/span 4/span 4;
      margin-left: -40px; }
    @include xs {
      grid-area: 3/1/span 3/-1;
      margin: 0 -20px; }
    & img {
      height: 100%;
      object-fit: cover;
      max-width: 100%; } }

  &__contacts {
    grid-area: 5/7/span 1/span 4;
    display: flex;
    flex-direction: column;
    background: $lightGrey;
    z-index: 0;
    padding: 50px 20%;
    @include md {
      grid-area: 5/4/span 1/-1; }
    @include xl {
      padding: 10%; }
    @include lg {
      padding: 5%; }
    @include xs {
      padding: 25px 35px;
      grid-area: 5/1/span 2/-1;
      margin-bottom: 20px; } }

  &__name {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 32px;
    color: $black;
    @include sm {
      margin-bottom: 20px;
      font-size: 5vw; }
    @include xs {
      font-size: 40px; } }

  &__tel {
    font-size: 31px;
    margin-bottom: 60px;
    color: $black;
    @include sm {
      margin-bottom: 20px;
      font-size: 4vw; }
    @include xs {
      font-size: 31px; } }

  &__mail {
    font-size: 19px;
    color: $main;
    text-decoration: underline;
    @include xs {
      font-size: 5vw; } }

  &__bckg {
    grid-area: 3/8/span 4/span 5;
    background: $main;
    margin-left: -#{$gap};
    @include xl {
      margin-right: -40px; }
    @include md {
      grid-area: 3/5/span 4/-1;
      margin-right: -40px; }
    @include xs {
      grid-area: 6/1/span 1/-1;
      margin-left: -20px; } }

  &__bckg-image {
    opacity: .1;
    width: 100%;
    height: 100%;
    object-fit: cover; } }
