.image-title {
  &__grid {
    grid-template-rows: 400px 80px;
    @include md {
      grid-template-rows: 600px 80px; }
    @include sm {
      grid-template-rows: 400px 50px; } }
  &__image {
    grid-area: 1/1/span 2/-1;
    @include xl {
      margin: 0 -40px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__title {
    grid-area: 2/3/span 2/span 8;

    @include md {
      grid-area: 2/1/span 2/-1; }
    @include sm; } }

