.info {
  color: $black;

  &__title {
    padding: 60px 0;
    font-size: 40px;
    text-align: center;
    @include sm {
      font-size: 25px;
      padding: 40px 0; } }

  &__descr {
    display: flex;
    @include md {
      flex-direction: column; } }

  &__guide {
    display: flex;
    @include xs {
      flex-direction: column; } } }

.info-details {
  display: flex;
  flex-direction: column;
  font-family: $contentFont;
  font-size: 20px;
  line-height: 30px;
  padding-left: 26px;
  @include xs {
    font-size: 16px;
    padding-left: 0; } }

.info-link {
  color: $main; }

.info-title {
  font-family: $mainFont;
  font-size: 25px;
  max-width: 140px;
  @include xs {
    font-size: 22px;
    max-width: unset;
    padding-bottom: 20px; } }

.info__guide + .info__guide {
  margin-left: 20px;
  @include md {
    margin-left: 0;
    margin-top: 20px; }
  @include sm {
    margin-top: 35px; } }

