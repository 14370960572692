.invitations {
  &__content {
    grid-template-rows: minmax(80px,1fr);
    @include sm {
      display: flex;
      flex-direction: column; } }

  &__text-top {
    grid-area: 1/7/span 1/span 6;
    padding: 0 0 160px 55px;
    & p {
      text-align: left; }
    @include md {
      grid-area: 1/4/span 1/span 3;
      padding: 0 0 45px 45px; }
    @include sm {
      padding: 20px 0; } }

  &__text-bottom {
    grid-area: 2/1/span 1/span 6;
    padding: 160px 55px 0 0;
    & p {
      text-align: right;
      @include sm {
        text-align: left; } }
    @include md {
      grid-area: 2/1/span 1/span 3;
      padding: 45px 45px 0 0; }
    @include sm {
      padding: 20px 0; } }

  &__image-top {
    grid-area: 1/1/span 1/span 6;
    margin-right: -#{$gap/2};
    @include xl {
      margin-left: -40px; }
    @include md {
      grid-area: 1/1/span 1/span 3; }
    @include sm {
      margin: 0 -20px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__image-bottom {
    grid-area: 2/7/span 1/span 6;
    margin-left: -#{$gap/2};
    @include xl {
      margin-right: -40px; }
    @include md {
      grid-area: 2/4/span 1/span 3; }
    @include sm {
      margin: 0 -20px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }

