.calendar {
  color: $black;
  @include sm {
    width: 80vw; }
  &__wrapper {
    position: relative; }
  &__close {
    background: transparent;
    position: absolute;
    top: -45px;
    right: -45px;
    @include sm {
      top: -7vw;
      right: -7vw; }
    @include xs {
      & svg {
        height: 16px;
        width: 16px; } } }

  &__body {
    background: $lightGrey;
    padding: 84px;
    @include sm {
      padding: 40px; }
    @include xs {
      padding: 20px; } }

  &__title {
    min-height: 130px;
    font-family: $mainFont;
    font-size: 40px;
    line-height: 50px;
    color: $main;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -33%;
    @include sm {
      font-size: 5vw;
      min-height: 65px;
      margin-top: -75px; }
    @include xs {
      font-size: 7vw;
      margin-top: -55px; } }

  &__labels {
    display: flex;
    justify-content: space-between;
    padding: 48px 0 35px 0;
    @include sm {
      padding: 32px 0; }
    @include xs {
      padding: 24px 0; } }

  &__grid {
    font-family: $contentFont;
    font-size: 24px;
    font-weight: 700; }

  &__week {
    width: 100%;
    & td {
      border: 1px solid rgba(112, 128, 143, 0.3); } }

  &__day-wrapper {
    position: relative;
    width: calc(100% / 7);
    min-width: 65px;
    color: $black;
    font-weight: 700;

    &.prev-month, &.next-month {
      opacity: .2; }

    &.current-month__past-days {
      opacity: .6; }

    &.current-month {
      background: #E7E1D6; }

    &.today {
      color: $main; }

    &.event-day {
      background-color: $main;
      color: $lightGrey;
      cursor: pointer;
      &:hover {
        .calendar__event-tip {
          opacity: 1;
          pointer-events: all; } }
      &::before {
        content: '';
        position: absolute;
        opacity: .3;
        width: 100%;
        height: 100%;
        background: url("../img/round_light.svg")no-repeat center;
        background-size: 80%;
        left: 1px;
        @include xs {
          left: 0;
          background-size: 90%; } } }


    @include sm {
      min-width: unset; } }

  &__event-tip {
      position: absolute;
      z-index: 3;
      bottom: calc(100% + 15px);
      left: -150%;
      width: 400%;
      min-height: 100%;
      color: $white;
      font-size: 16px;
      font-weight: 400;
      padding: 12px 28px;
      background: $main;
      border-radius: 10px;
      pointer-events: none;
      opacity: 0;
      transition: opacity $tr ease;
      @include sm {
        padding: 12px; }
      @include xs {
        font-size: 12px; }
      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 17px solid $main;
        left: calc(50% - 15px);
        bottom: -15px; } }

  &__day {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    .today & {
      border: 1px solid #8D6F74;
      margin: -1px;
      width: calc(100% + 2px); } }

  &__day-number {
    position: absolute;
    font-size: 24px;
    text-align: center;
    padding-top: 30%;
    width: 100%;
    height: 100%;
    @include sm {
      padding-top: 37%;
      font-size: 19px; }
    @include xs {
      padding-top: 30%;
      font-size: 17px; } } }

