.section-subtitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__image {
    & svg {
      width: 100%; }
    @include sm {
      margin: 0 -40px;
      padding-top: 30px; }
    @include xs {
      padding-top: 15px; } } }
