.header {
  font-family: $mainFont;
  position: absolute;
  width: 100%;

  &__logo-text {
    grid-area: 1/1/span 1/span 2;
    //width: 220px
    display: block;
    font-size: 32px;
    color: $lightGrey;
    text-align: center;
    padding: 30px 0;
    z-index: 3;
    @include md {
      grid-area: 1/3/span 1/span 2;
      font-size: 42px;
      line-height: 46px;
      width: auto;
      text-align: left;
 }      //padding: 30px
    @include sm {
      font-size: 28px;
      line-height: 25px;
      padding: 20px 20px 20px 0; } }


  &__tel {
    grid-area: 1/3/span 1/span 3;
    display: block;
    font-size: 25px;
    color: $lightGrey;
    align-self: center;
    justify-self: center;
    @include xl {
      grid-area: 1/3/span 1/span 3; }
    @include lg {
      display: none; } }

  &__navbar {
    grid-template-rows: auto;
    position: relative;
    z-index: 1;
    @include md {
      grid-template-rows: 190px auto;
      width: 100%;
      left: unset; }
    @include sm {
      grid-template-rows: auto; } }

  &__menu {
    grid-area: 1 / 6 / span 1 / -1;
    align-self: center;
    @include lg {
      grid-area: 1 / 5 / span 1 / -1; } }

  &__modal {
    width: 1px;
    height: 1px;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform $tr ease-in-out;

    &.is-open {
      opacity: 1;
      transform: translateY(0);
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      //backdrop-filter: blur(20px)
      @include md {
        z-index: 8; } } }

  &__burger-menu {
    cursor: pointer;
    display: none;
    position: relative;
    align-self: center;
    justify-self: flex-end;
    z-index: 5;
    transition: $tr;
    grid-area: 1 / 6 / span 1 / -1;
    @include md {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center; }


    &.active {
      & .header__burger-lines {
        background-color: transparent;

        &::before {
          top: 0;
          transform: rotate(45deg); }

        &::after {
          top: 0;
          transform: rotate(-45deg); } } } }


  &__burger-decor {
    opacity: .2;
    width: 73px;
    height: 73px;
    transition: opacity $tr;

    .active & {
      opacity: 0; } }

  &__burger-button {
    position: absolute;
    height: 22px;
    width: 33px;
    display: flex;
    align-items: center; }

  &__burger-lines {
    width: 33px; }

  &__burger-lines,
  &__burger-lines::before,
  &__burger-lines::after {
    position: absolute;
    height: 3px;
    background-color: $lightGrey;
    transition: $tr; }

  &__burger-lines {
    &::before {
      content: '';
      top: -8px;
      width: 27px;
      left: 3px; }

    &::after {
      content: '';
      top: 8px;
      width: 27px;
      right: 3px; } }

  &__lang-switcher {
    grid-area: 2/12/span 1/span 1;
    justify-self: end;
    padding-right: 23px;
    @include xl {
      grid-area: 1/12/span 1/span 1;
      align-self: end;
 }      //margin-bottom: 25px
    @include md {
      grid-area: 1/5/span 1/span 1;
      align-self: center;
      justify-self: flex-end;
      margin-bottom: 0;
      padding-right: 0;
      margin-right: -20px; }
    @include sm {
      grid-area: unset;
      position: absolute;
      z-index: 2;
      left: 23px;
      top: calc(100% + 40px); }
    @include xs {
      top: calc(100% + 40px); } }

  &__logo {
    background: $lightMain;
    grid-area: 2/1/span 1/span 2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    padding: 30px;
    //width: 220px
    //height: 220px
    & img {
      width: 100%;
      height: 100%; }

    @include md {
      grid-area: 1/1/span 2/span 2;
      width: 220px;
      height: 220px; }

    &.active {
      background: unset; }

    @include sm {
      width: 78px;
      height: 78px;
      padding: 0;
      background: unset;
      align-self: center;
      & img {
        width: 100%;
        height: 100%; } } } }
