.out {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

.main-content {
  flex: 1 0 auto; }

.inner {
  position: relative;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  @include sm {
    padding-right: 20px;
    padding-left: 20px; } }

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr minmax(80px, 1fr);
  grid-column-gap: $gap;
  @include md {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto; } }

.no-scroll {
  overflow: hidden !important; }
