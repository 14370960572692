.life {
  &__content {
    grid-template-rows: auto minmax(80px, 1fr); }

  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-bottom: 110px;
    position: relative;
    @include md {
      grid-area: 1/1/span 1/-1;
      margin-bottom: 70px; }
    @include sm {
      margin-bottom: 20px; } }

  &__images {
    position: relative;
    grid-area: 2/1/span 1/span 6;
    @include md {
      grid-area: 2/1/span 1/-1; } }

  &__images-wrapper {
    display: flex;
    justify-content: space-around;
    position: absolute;
    right: 0;
    width: 50vw;
    height: 100%;
    @include md {
      position: relative;
      width: 100vw;
      left: -40px; }
    @include sm {
      left: 0; } }

  &__card {
    width: 100%;
    height: calc(100% + 60px);
    @include md {
      height: 100%; } }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__text {
    grid-area: 2/7/span 1/-1;
    background: $main;
    margin-left: -#{$gap};
    position: relative;
    padding: 120px 0 40px 67px;
    @include md {
      grid-area: 3/1/span 1/-1;
      margin-left: -40px;
      padding: 120px 60px 40px 67px; }
    @include sm {
      margin: 0 -20px;
      padding: 60px 40px 40px; }
    @include xs {
      padding: 60px 30px 30px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 140vw;
      height: 100%;
      left: -40vw;
      background: $main;
      z-index: -1;
      @include md {
        display: none; } }

    &::after {
      content: '';
      position: absolute;
      top: 30px;
      left: 67px;
      width: 92px;
      height: 10px;
      background: $lightGrey;
      @include sm {
        left: 40px; }
      @include xs {
        left: 30px; } } } }
