.footer {
  font-family: $contentFont;
  background: $main;
  color: $lightGrey;
  padding: 53px 0;
  @include xs {
    padding: 20px 0; }

  &__modal {
    width: 1px;
    height: 1px;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform $tr ease-in-out, opacity $tr ease-in-out;

    &.is-open {
      opacity: 1;
      transform: translateY(0);
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(87, 25, 39, 0.7);
      backdrop-filter: blur(20px); } }


  &__inner {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 75px;
    padding-right: 75px;
    @include md {
      padding-left: 40px;
      padding-right: 40px; }
    @include sm {
      padding-left: 20px;
      padding-right: 20px; } }

  &__content {
    grid-template-rows: repeat(2, 53px); }

  &__logo {
    grid-area: 1/1/span 2/span 2;
    justify-self: flex-start;
    width: 106px;
    height: 106px;
    @include md {
      grid-area: 1/1/span 1/span 2; }
    @include xs {
      width: 69px;
      height: 69px;
      align-self: flex-end; } }

  &__logo-img {
    height: 100%; }

  &__contacts {
    grid-area: 1/3/span 2/span 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 430px;
    @include md {
      grid-area: 1/3/span 1/span 4;
      justify-self: flex-end;
      width: auto; } }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
    @include md {
      flex-wrap: wrap;
      justify-content: flex-end; } }

  &__mail-link {
    text-decoration: underline; }

  &__address {
    text-align: end; }

  &__copyright {
    grid-area: 1/10/span 2/-1;
    align-self: center;
    justify-self: flex-end;
    text-align: end;
    width: max-content;
    @include md {
      grid-area: 2/4/span 1/span 3; }
    @include xs {
      grid-area: 2/1/span 1/-1;
      margin-top: 25px;
      justify-self: center; } } }

