.values {
  &__values-title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-top: -50px; } }

  &__values-subtitle {
    grid-area: 2/3/span 1/span 8;
    @include md {
      grid-area: 2/1/span 1/-1;
      padding-top: 30px; }
    @include xs {
      padding-top: 15px; } }

  &__values-text {
    grid-area: 3/3/span 1/span 8;
    padding: 30px 0 90px 0;
    @include md {
      grid-area: 3/1/span 1/-1; }
    @include sm {
      padding: 30px 0; } }

  &__values-image {
    grid-area: 4/1/span 1/-1;
    @include xl {
      margin: 0 -40px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include md {
        width: calc(100% + 40px); } } }

  &__meeting-content {
    grid-template-rows: minmax(80px, 1fr); }

  &__meeting-title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -75px;
    margin-bottom: 140px;
    @include md {
      grid-area: 1/1/span 1/-1;
      margin-bottom: 120px; }
    @include sm {
      margin-bottom: 80px;
      margin-top: -50px; } }

  &__meeting-description {
    grid-area: 2/1/span 2/-1;
    @include xl {
      margin: 0 -40px; }
    @include sm {
      margin: 0 -20px; } }

  &__arrow-top {
    padding: 110px 0 90px;
    width: 100%;
    grid-area: 4/1/span 1/-1;
    @include xl {
      padding: 40px 0; }
    @include xs {
      display: none; } } }
