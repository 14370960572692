$white: #EFF4F2;
$black: #3D3D3D;
$lightGrey: #DCD6CA;
$darkGrey: #747474;
$lightMain: #571927;
$main: #47101C;

$mainFont: "Playfair", sans-serif;
$contentFont: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";



$gap: 20px;

$tr: 0.3s;
