.delimiter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &__image {
    @include md {
      margin: 0 -40px; } }
  & svg {
    width: 100%; } }

