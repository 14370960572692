.text-main {
  font-family: $mainFont;
  color: $black;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  padding: 80px 0;
  @include sm {
    padding: 40px 0; }
  &__grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: 1fr;
    grid-column-gap: $gap;
    @include md {
      grid-template-columns: repeat(6,1fr); } }
  &__text {
    grid-area: 1/3/span 1/span 8;
    @include md {
      grid-area: 1/1/span 1/-1; } } }
