.image-top {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: minmax(80px,1fr);
    grid-column-gap: $gap;
    @include md {
      display: flex;
      flex-direction: column; } }
  &__text {
    grid-area: 1/3/span 2/span 8;
    padding: 50px 60px;
    background: $main;
    z-index: 1;
    @include md {
      padding: 30px 45px;
      margin: 0 -40px;
      z-index: 0; } }

  &__image {
    grid-area: 2/1/span 2/-1;
    margin-bottom: 90px;
    @include xl {
      margin: 0 -40px 90px; }
    @include md {
      margin: 0 -40px 50px; }
    @include sm {
      margin: 0 -40px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
