.about-section {
  &__title {
    grid-area: 1/3/span 1/span 8;
    margin-top: -80px;
    @include md {
      grid-area: 1/1/span 1/-1; }
    @include sm {
      margin-top: -50px; } }

  &__subtitle {
    grid-area: 2/3/span 1/span 8;
    @include md {
      grid-area: 2/1/span 1/-1; } }

  &__text {
    grid-area: 3/3/span 1/span 8;
    padding: 30px 0 90px 0;
    @include md {
      grid-area: 3/1/span 1/-1;
      padding: 60px 0; }
    @include xs {
      padding: 30px 0; } }

  &__text-accent {
    grid-area: 4/3/span 2/span 8;
    padding: 50px 60px;
    background: $main;
    z-index: 1;
    @include md {
      grid-area: 4/1/span 1/-1;
      padding: 30px 45px;
      margin: 0 -40px;
      z-index: 0; } }

  &__image {
    grid-area: 5/1/span 2/-1;
    margin-bottom: 90px;
    @include xl {
      margin: 0 -40px 90px; }
    @include md {
      margin: 0 -40px 50px; }
    @include sm {
      margin: 0 -40px; }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__description {
    grid-area: 7/1/span 2/-1;
    margin-top: 40px;
    @include xl {
      margin: 0 -40px; }
    @include sm {
      margin: 0 -20px; } }

  //&__description-image
  //  grid-area: 7/1/span 2/span 5
  //  +xl
  //    margin-left: -40px
  //  +md
  //    grid-area: 7/1/span 2/span 2
  //    margin-top: 70px
  //    z-index: 1
  //  +sm
  //    grid-area: 8/1/span 1/-1
  //    margin-right: -40px
  //    margin-top: 0
  //
  //  & img
  //    width: 100%
  //    height: 100%
  //    object-fit: cover
  //
  //&__description-text
  //  grid-area: 8/6/span 1/-1
  //  background: $lightGrey
  //  padding: 50px 60px
  //  margin-left: -#{$gap}
  //  margin-top: 80px
  //  position: relative
  //  +xl
  //    margin-right: -40px
  //  +md
  //    grid-area: 8/3/span 1/-1
  //    margin-top: 0
  //    &::after
  //      content: ''
  //      position: absolute
  //      top: 0
  //      right: 100%
  //      width: 25%
  //      height: 100%
  //      background: $lightGrey
  //  +sm
  //    grid-area: 7/1/span 1/-1
  //    padding: 20px 40px
  //    margin: 0 -40px
  //    &::after
  //      display: none

  &__arrow-top {
    padding: 110px 0 90px;
    width: 100%;
    //overflow: hidden
    grid-area: 9/1/span 1/-1;
    @include md {
      padding: 40px 0; }
    @include xs {
      display: none; } } }

