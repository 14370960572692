.events-future {
  &__title {
    padding: 60px 0;
    @include md {
      padding: 40px; }
    @include sm {
      padding: 20px 0; } }

  &__slider {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include md {
      margin: 0 -40px; } }

  &__slider-arrows {
    display: none;
    @include sm {
      display: block;
      padding: 30px 0; } }

  &__card {
    width: 50%;
    max-width: 600px;
    margin-bottom: 60px;
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin: 0 20px;
      width: 100vw !important;
      max-width: unset; } }

  &__link {
    max-width: 400px;
    margin: 0 auto; } }



