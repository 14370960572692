.events-section {
  padding-top: 120px;
  @include md {
    padding-top: 60px; }

  &__content {
    grid-template-rows: minmax(80px, 1fr) !important;
    @include sm {
      grid-template-rows: minmax(50px, 1fr) !important; } }

  &__title {
    grid-area: 1/3/span 2/span 8;
    @include md {
      grid-area: 1/1/span 2/-1; } }

  &__events-block {
    grid-area: 2/1/span 1/-1;
    padding: 120px 60px 60px;
    background: $main;
    @include xl {
      margin: 0 -40px; }
    @include md {
      padding: 120px 50px 60px; } }

  &__events-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
    @include md {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 60px; }
    @include sm {
      display: flex;
      flex-direction: column; } }

  &__item {
    color: $white;
    font-family: $mainFont;
    font-size: 28px;
    min-height: 180px;
    position: relative;
    padding-left: 30px;
    @include sm {
      min-height: unset; }

    &::before {
      content: url("../img/decor.svg");
      position: absolute;
      left: -10px; } }

  &__gallery {
    grid-area: 5/1/span 1/-1;
    position: relative;
    width: 100vw;
    left: calc((-100vw + 1340px) / 2);
    @include xl {
      left: -40px; }
    @include sm {
      left: -20px; } }

  &__images-wrapper {
    display: flex;
    justify-content: space-around; }

  &__card {
    width: 100%; }

  &__image {
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
