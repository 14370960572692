.map {
  color: $black;
  margin: 40px 0;

  &__wrapper {
    padding: 40px;
    background: $white;
    @include xs {
      padding: 20px; } }

  &__container {
    padding-bottom: 33%;
    @include md {
      padding-bottom: 50%; }
    @include xs {
      padding-bottom: 100%; } } }
