.events-past {
  &__title {
    padding: 60px 0;
    @include md {
      padding: 40px; }
    @include sm {
      padding: 20px 0; } }

  &__slider {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    margin-left: calc((-100vw + 1340px) / 2);
    @include xl {
      margin-left: -40px;
      width: calc(100vw - 17px); }
    @include sm {
      width: 100vw;
      margin-right: -40px; } }


  &__slider-arrows {
    display: none;
    @include sm {
      display: block;
      padding: 30px 0; } }

  &__card {
    width: 25%;
    margin-bottom: 60px;
    @include xlg {
      width: 33.33%; }
    @include md {
      width: 50%;
      margin-bottom: 20px; }
    @include sm {
      margin: 0 20px;
      width: 100vw !important;
      max-width: unset; } }

  & .slick-list {
    margin-right: -20px; } }
